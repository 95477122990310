/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

.StripePayments {
    &-Method {
        display: flex;
        align-items: center;

        img {
            width: 40px;
            padding-inline-end: 10px;
        }
    }

    &-CardElement {
        margin-block-start: 2rem;
        min-height: 150px;
    }

    &-CvcElement {
        margin-block-start: 2rem;
    }

    &-Select {
        z-index: 100;
    }

    .Loader {
        z-index: 1000;
    }
}
