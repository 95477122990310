/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Faq {
    &-Wrapper {
        padding-inline-start: 0;
        padding-inline-end: 0;

        div.collapsed {
            font-family: 'futura-pt-light', sans-serif;
            width: 100%;
            background-color: transparent;
            text-align: start;
            border: none;
            border-bottom: 1px solid #363b4f;
            padding: 10px 0;
            border-radius: 0;

            a.accordion-toggle {
                color: #363b4f;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 20px;
            }
        }

        div.collapsed:hover {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #00295a;
            color: #344079;
            box-shadow: none;
        }

        .panel-body, .panel-body a {
            font-family: 'futura-pt-light', sans-serif;
            border: none;
            color: #223041;
            font-size: 1.2rem;
            font-weight: 400;
        }


        .panel-body > :first-child {
            padding-block-start: 5px;
        }

        .panel-body ul {
            li {
                display: flex;
                margin-block-end: 0;
            }

            li::before {
                position: relative;
                inset-inline-start: 0;
                padding-inline-end: 10px;
            }
        }
    }
}
