/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PaypalPayment {
    &-Label {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &-Image {
        width: 70px;
        height: 45px;
    }

    &-WhatIsPaypal {
        border-bottom: var(--link-underline);
        color: var(--link-color);
        font-size: 14px;
        font-weight: 700;

        &:hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }
}
