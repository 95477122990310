/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.FaqsPage {
    @include desktop {
        padding-block-start: 20px;
    }

    &-Heading {
        @include desktop {
            font: normal normal 300 30px/24px 'Lato',sans-serif !important;
            letter-spacing: 9px !important;
            text-align: center !important;
            text-transform: uppercase;
        }
    }
}
