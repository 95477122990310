/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.InvitePointsPage {
    .privPolDisabled {
        opacity: 0.5;
    }

    .title_suscripcion {
        color: rgb(0,41,90);
        font: 18px/25px 'Lato', sans-serif;
        text-align: center;
        text-transform: uppercase;
        margin-block-start: 30px;
        margin-block-end: 30px;
        font-weight: 300;
    }

    .Form {
        @include desktop {
            width: 70%;
            margin: auto;
        }

        .Field-Wrapper_type_email {
            .Field-ErrorMessage {
                text-align: center;
            }
        }

        .Field_type_email {
            text-align: center;
            margin-block-start: 30px;

            .Field-LabelContainer {
                display: initial;
                text-align: center;

                .Field-Label {
                    font: 14px/20px 'Lato',sans-serif;
                    font-weight: 300;
                    color: #4a4a4a;
                }
            }

            .invite_email {
                border: 1px solid #a0a0a0;
            }
        }

        .accept-term-continer {
            text-align: center;

            .InvitePointsForm-accept_term_label {
                display: inline-flex;

                .Field_type_checkbox {
                    margin-block-start: 0px;
                }
            }

            .privacy-policy-text {
                line-height: 16px;
                font-size: 12px;

                .privacy-policy-link {
                    color: rgb(15,35,63);
                }
            }
        }
    }

    .lista-condiciones-lopd {
        margin-block-start: 30px;

        li {
            font-size: 10px;
            line-height: initial;
            font-family: 'futura-pt-book', sans-serif;
            color: #344079;

            &::before {
                inset-inline-start: -1em;
                inset-block-start: 0em;
            }

            .privacy-policy-link {
                font-size: 10px;
            }
        }
    }

    .InvitePointsForm-Suscribe-Button {
        width: 50%;
        display: block;
        margin: 40px auto 0;

        @include mobile {
            background-color: white;
            margin: 0 25%;
            color: #363b4f;
            border: 1px solid #363b4f;
            height: 40px;

            &:hover {
                background-color: #363b4f;
                color: white;
            }
        }
    }

    .container-result-suscripcion {
        margin: 15px 10px;
        text-align: center;
        font-size: 14px;

        .result-suscripcion-ok {
            color: rgb(0,128,0);
        }

        .result-suscripcion-false {
            color: rgb(255,0,0);
        }
    }
}
