/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.NewsletterSubscription {
    &-TACLabel {
        display: flex;
        justify-content: center;

        & .Text {
            float: inline-start;
            margin-block-start: 15px;

            @include desktop {
                margin-block-start: 25px;
                font-weight: 700;
                font-size: 14px;
                font-family: 'Lato', sans-serif !important;
            }
        }

        & .Field-Wrapper {
            float: inline-start;
        }
    }

    &-body {
        padding: 0 20px;
        margin-block-start: 60px;
    }

    &-title {
        font: 25px/29px 'Lato', sans-serif;
        font-weight: 300;
        color: #344079;
        letter-spacing: 0;
        text-transform: uppercase;
        margin-block-end: 20px;
        overflow: hidden;
        text-align: center;
    }

    &-info {
        padding-block-start: 15px !important;
        padding-block-end: 15px !important;
        font: 18px/1.8 'futura-pt-light', sans-serif;
        font-weight: 600;
        letter-spacing: 2px !important;
        color: #223041;
        text-align: center;
    }

    &-Terms {
        font-family: Lato, Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #223041;
    }

    & .FieldForm-Body {
        & input {
            border-radius: 30px !important;
            border-color: #0f233f !important;
        }

        & button {
            border-radius: 0;
            position: absolute;
            padding: 10px;
            text-transform: uppercase;
            color: #fff;
            font: 15px/17px 'Lato',sans-serif;
            font-weight: 300;
            width: 45px;
            background-image: url(https://cdn.elganso.com/media/enter3.png);
            inset-inline-end: 2px;
            height: 41px;
            inset-block-start: 4px;
            background-repeat: no-repeat;
            border: none;
            background-position: center;

            &:hover {
                height: 41px;
            }
        }
    }

    &-ExpandableContent {
        padding: 0 20px;
        margin-block-start: 5px;

        &Heading {
            padding-block-start: 10px;
            font: 13px/1.8 'Lato', sans-serif;
            font-weight: 300;
            letter-spacing: 2px !important;
            color: #00295a;
        }

        li {
            list-style-type: disc;
            font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;
            font-size: 14px;
            color: #223041;
        }

        li::before {
            inset-inline-start: -18px;
        }

        .modal-header h4 {
            font: 18px/22px Lato,sans-serif;
            font-weight: 300;
            letter-spacing: 5px;
            text-align: center;
            color: #00295a;
            margin-block-start: 15px;
            display: block;
        }
    }

    .Button {
        margin-block-start: 0;
    }

    &-Categories {
        margin-block-start: 20px;

        .textCategories {
            text-align: center;
        }
    }

    &-CategoriesForm {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-Label {  
        display: flex;
        align-items: center;
        width: 100px;
        margin: 2px 0;

        .Text {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &-Checkbox {
        margin-block-start: 0;
    }
}

.Newsletter-Popup {
    li {
        list-style-type: disc;
        font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;
        font-size: 14px;
        color: #223041;
    }

    li::before {
        inset-inline-start: -18px;
        content: inherit;
    }

    .modal-header h4 {
        font: 18px/22px Lato,sans-serif;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        color: #00295a;
        margin-block-start: 15px;
        display: block;
    }

    .newsletter_suscripcion {
        color: rgb(0,41,90);
        text-transform: uppercase;
        font: 18px 'Lato', sans-serif;
        font-weight: 300;
        text-align: center;

        .title_newsletter_suscripcion {
            color: rgb(0,41,90);
            text-transform: uppercase;
            font: 22px 'Lato', sans-serif;
            margin-block-start: 30px;
            margin-block-end: 30px;
            font-weight: 700;
        }

        .body_newsletter_suscripcion {
            margin-block-start: 30px;
            margin-block-end: 10px;
        }

        .footer_newsletter_suscripcion {
            margin-block-end: 30px;
        }
    }

    .Popup-Content {
        .Popup-Header {
            .Popup-CloseBtn {
                inset-block-start: 20px !important;
            }
        }
    }

    @include mobile {
        .Popup-Content {
            height: fit-content;
        }
    }
}
