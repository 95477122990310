@font-face {
    font-family: 'futura-pt-book';
    src: url(/style/fonts/FuturaPT-Book.woff2) format('woff2'),
        url(/style/fonts/FuturaPT-Book.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura-pt-demi';
    src: url(/style/fonts/futura-pt-demi.woff2) format('woff2'),
        url(/style/fonts/futura-pt-demi.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura-pt-heavy';
    src: url(/style/fonts/futura-pt-heavy.woff2) format('woff2'),
        url(/style/fonts/futura-pt-heavy.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura-pt-light';
    src: url(/style/fonts/FuturaPT-Light.woff2) format('woff2'),
        url(/style/fonts/FuturaPT-Light.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura-pt-medium';
    src: url(/style/fonts/futura-pt-medium.woff2) format('woff2'),
        url(/style/fonts/futura-pt-medium.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHh30AUi-qJCY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHh30AXC-q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50XSwaPGR_p.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50XSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Made Tommy Light';
    src: url(../fonts/MADE_TOMMY/MADE-TOMMY-Light.otf);
}

@font-face {
    font-family: 'Made Tommy Medium';
    src: url(../fonts/MADE_TOMMY/MADE-TOMMY-Medium.otf);
}

@font-face {
    font-family: 'Made Tommy Bold';
    src: url(../fonts/MADE_TOMMY/MADE-TOMMY-Bold.otf);
}

$futura-pt-book: 'futura-pt-book', sans-serif;
$futura-pt-demi: 'futura-pt-demi', sans-serif;
$futura-pt-heavy: 'futura-pt-heavy', sans-serif;
$futura-pt-light: 'futura-pt-light', sans-serif;
$futura-pt-medium: 'futura-pt-medium', sans-serif;
$Lato: 'Lato', sans-serif;
