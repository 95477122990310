/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBTarjetaRegalo {
    display: inline-flex;
    width: 49%;
    padding: 20px 10px;
    position: relative;

    @include desktop {
        width: 33%;
    }

    .YBTarjetaRegalo-Picture {
        padding-block-end: 0%;

        .Image-Image {
            height: auto;
        }
    }
    
    .contenido-tarjeta-regalo {
        position: absolute;
        float: inline-start;
        inset-block-start: 45%;
        font-weight: bold;
        width: 85%;

        @include desktop {
            inset-block-start: 50%;
            width: 100%;
        }

        p {
            margin-inline-start: 15px;
            margin-block-end: 5px;
            font-weight: 500;
            color: #223041;

            @include desktop {
                margin-inline-start: 45px;
                font-weight: 500;
                color: #223041;
                margin-block-end: 15px;
            }

            .cod-uso {
                font-weight: bolder;

                @include desktop {
                    font-weight: bolder;
                }
            }
        }
    }
}
