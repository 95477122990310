/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.SearchField {
    @include desktop {
        &-Input, #search-field {
            height: 35px;
        }
    
        &-SearchIcon {
            inset-block-start: calc(50% - 10px);
            width: 20px;
        }

        &-CloseIcon {
            width: 20px;
        }
    }

    @include mobile {
        padding: 0px 15px;

        &-Input {
            border: none; 
            border-bottom: 1px solid #00295a;
            padding: 5px 10px;
            transition: .3s;
            height: 32px;

            &:focus {
                border: none;
                border-bottom: 1px solid #00295a;
            }
        }

        &-SearchIcon {
            width: 15px;
            height: 15px;
            inset-block-start: 30%;
            inset-inline-end: 6px;
        }

        & .fa-magnifying-glass {
            width: 14px;
            height: 14px;

            & path {
                fill: #B4ACAA;
            }
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .SearchField {
            &-SearchIcon {
                inset-block-start: calc(50% - 10px);
            }
        }
    }
}
