/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

@media only screen and (min-width: 768px) {
    .CarouselScroll {
        &-Content {
            transform: none;
            
            &Wrapper {
                // &::-webkit-scrollbar {
                //     display: none;
                // }
                display: block;
                overflow: inherit;
                // scrollbar-width: none;
            }
        }
    }
}
