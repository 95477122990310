/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBNewRmaRow {
    & .rma-order-item-row {
        width: 100%;
        margin-block-end: 15px;
        margin-block-start: 10px;
        display: inline-flex;
        
        @include desktop {
            justify-content: center;
        }

        & .rma-order-item-check {
            width: 10%;
            margin: auto 0;
            padding-inline-start: 10px;

            & .Field_type_checkbox {
                margin: unset;
            }
        }

        & .rma-order-item-desc {
            width: 90%;
            display: inline-flex;

            & .rma-order-item-image {
                width: 100px;

                @include desktop {
                    width: 150px;
                }
            }

            & .rma-order-item-desc-datos {
                width: calc(100% - 110px);
                margin-inline-start: 10px;

                & p {
                    margin-block-end: 3px;
                }

                & .item-name {
                    font-size: 14px;
                    color: #344079;
                    font-weight: 700;
                }

                & .itemAttributeLabel {
                    font-size: 14px;
                    font-weight: 700;
                }

                & .itemAttributeValue {
                    font-size: 14px;
                    margin-inline-end: 10px;
                }

                & .item-sku {
                    margin-inline-end: 10px;
                }

                & .Field.Field_type_select {
                    margin-block-start: 5px;
                }

                & .FieldSelect {
                    margin-block-end: 10px;
                }

                & .FieldSelect-Select {
                    height: 30px;
                    line-height: 30px;
                    padding-inline-start: 15px;
                    padding-inline-end: 30;
                    padding-block-start: 0px;
                    padding-block-end: 0px;
                }
            }
        }

        .cambioNoPermitido {
            color: red;
            font-size: 14px;
            margin-block-start: 20px;
            text-transform: uppercase;
        }
    }
}
