/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ContactForm {
    @include mobile {
        font-family: "futura-pt-light", sans-serif;

        input, textarea, select {
            padding: 5px 10px;
            font-size: 16px;
        }

        .Form .Button {
            background-color: white;
            margin: 0 25%;
            color: #363b4f;
            border: 1px solid #363b4f;
            height: 40px;

            &:hover {
                background-color: #363b4f;
                color: white;
            }
        }
    }

    &-TACLabel {
        font-family: Lato, sans-serif;
        color: #223041;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 14px;
        margin-block-start: 30px;

        @include mobile {
            font-family: "futura-pt-light", sans-serif;
            margin-block-start: 15px;
            color: #363b4f;
        }
    }

    &-TACLink {
        width: auto !important;
        min-width: 10px !important;
        margin-block-start: 0 !important;
        font-size: 14px;
        font-weight: bold;
        margin-inline-start: 2px;
        color: #1979c3;
        font-family: Lato, sans-serif;

        @include mobile {
            font-family: "futura-pt-light", sans-serif;
            color: #363b4f;
        }
    }

    .privPolDisabled {
        background-color: #949494;
        border: none;
    }

    &-ReCaptcha {
        margin-block-start: 24px;
        margin-block-end: 24px;
        
        div {
            margin-block-start: 24px;
            margin-inline-start: auto;
            margin-inline-end: auto;
        }
    }
}
