/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBSiteMap {
    .page-title {
        display: none;
        font: 25px/29px 'Lato', sans-serif;
        font-weight: 300;
        margin-block-start: 15px;
        letter-spacing: 9px;
        text-align: center;
        color: #00295a;
        text-transform: uppercase;
    }

    display: block;
    overflow: hidden;

    a {
        color: #344079;
        font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;

        &:hover, &:visited {
            color: #1979c3;
        }
    }

    ul {
        line-height: 26px;
        list-style: none;
        padding-inline-start: 1.5em;

        li {
            margin-block-end: 1rem;
        }
    }

    .am-sitemap-column {
        float: inline-start;
    }

    .sitemapSection .std {
        padding-block-end: 35px;
    }

    li::before, ul > li::before {
        content: '';
    }

    &-title {
        font: normal normal 300 30px/24px 'Lato', sans-serif !important;
        letter-spacing: 9px !important;
        text-align: center !important;
        color: #00295a !important;
        padding-block-end: 25px;
    }

    &-products, &-categories, &-cmsPages {
        display: block;
        float: inline-start;

        @include desktop {
            margin-block-start: 35px;
        }

        @include mobile {
            margin-block-start: 15px;
        }

        width: 100%;
    }

    &-SearchField {
        margin: 0 auto;
        width: 50%;
        display: block;
        margin-block-start: 50px;
        
        .SearchField-Input:focus {
            box-shadow: 0 0 3px 1px #68a8e0;
        }

        input {
            border: 1px solid #d7d7d7 !important;
            font-size: 14px;
            height: 40px;
        }

        label {
            display: block;
            padding-block-end: 5px;
            font-weight: bold;
            font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;
            color: #344079;
            margin-block-end: 5px;
            font-size: 14px;
        }
    }

    .sitemapTree-0 li > ul {
        padding-inline-start: 0;
    }
    
    .sitemapSection {
        padding-block-end: 35px;
    }
    
    .sitemapSection h2 {
        padding-block-end: 15px;
    }
    
    
    .sitemapSection .am-sitemap-wrap a.disabledLink {
        color : #333333;
        cursor: default;
        text-decoration: none;
    }
    
    .sitemapSection .am-sitemap-wrap a .text-highlight {
        color: #F94D1A;
    }
    
    .am-sitemap-cell {
        padding: 0 5px 5px 0
    }
    
    .am-clearfix::after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
    
    .am-clearfix {
        display: inline-block;
        width: 100%;
    }
    
    html[xmlns] .am-clearfix {
        display: block;
    }
    
    * html .am-clearfix {
        height: 1%;
    }
    
    .htmlsitemap-search-input {
        display: block;
        width: 100%;
    }
    
    .htmlsitemap-search-wrapper label {
        display: block;
        padding-block-end: 5px;
        font-weight: bold;
    }
    
    
    .htmlsitemap-search-wrapper {
        margin: 0 auto;
        width: 50%;
        display: block;
    }
    
    .sitemapSection ul {
        padding-inline-start: 1.5em;
    }
    
    .sitemapSection ul li {
        margin-inline-start: 2em;
    }
    
    .am-sitemap-cell li {
        list-style-type: none;
        word-wrap: break-word;
    }
    
    @media screen and (max-width: 400px) {
        .am-sitemap-column {
            width: 100% !important;
        }
    
        .am-sitemap-cell {
            padding-block-end: 0;
        }
    
        .am-sitemap-list {
            margin-block-end: 0;
        }
    }
    
    @media screen and (min-width: 401px) and (max-width: 768px) {
        .am-sitemap-column {
            width: 50% !important;
        }
    }
}
