/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.StoresPage {
    & .FieldSelect-Select {
        & option {
            color: #6a6a6a;
        }

        -webkit-appearance: none;
        color: #6a6a6a;
        background-color: #fff;
        border-color: #ccc;
        padding: 6px 12px;
    }

    &-LocateButton button {
        width: 100%;
        border-radius: 0;
        border: solid 1px #00295a!important;
        background-color: #00295a;
        color: #fff;
        font: 15px/17px 'Lato',sans-serif;
        font-weight: 300;
        padding: 10px;
        text-transform: capitalize;
        margin-block-start: 2rem;
        margin-block-end: 4rem;
        height: 40px;

        &:hover {
            height: 40px !important;
        }


        @include mobile {
            background-color: white;
            width: 50%;
            margin-inline-start: 25%;
            margin-inline-end: 25%;
            color: #363b4f;
            border: 1px solid #363b4f;
            height: 40px;

            &:hover {
                background-color: #363b4f;
                color: white;
            }
        }
    }

    & .FieldSelect-Clickable {
        border-radius: 0;
        margin-block-end: 20px;
        height: 34px;
    }

    &-loc-cabecera p {
        font: 13px/16px 'Lato',sans-serif;
        font-weight: 300;
        text-align: center;
        color: #6a6a6a;
        padding-inline-end: 5px;
        padding-inline-start: 5px;
    }

    &-Form {
        @include mobile {
            font-family: "futura-pt-light", sans-serif;
        }

        & h1 {
            @include desktop {
                font: 17px/21px 'Lato',sans-serif;
                font-weight: 300;
                letter-spacing: 5px;
                text-align: center;
                color: #00295a;
                margin-block-end: 2rem;
                margin-block-start: 2rem;
                text-transform: uppercase;
            }

            @include mobile {
                font-family: "futura-pt-book", sans-serif;
                font-size: 18px;
                margin: 0 0 20px;
                padding: 10px 0 0;
                color: #363b4f;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }

        position: relative;
        display: block;
        padding-inline-end: 30px;
        padding-inline-start: 30px;

        @include desktop {
            padding-inline-start: 35%;
            padding-inline-end: 35%;
        }
    }

    &-Map {
        height: 80vh;
        -webkit-filter: grayscale(60%);
        -moz-filter: grayscale(60%);
        -ms-filter: grayscale(60%);
        -o-filter: grayscale(60%);
        filter: grayscale(60%);
    }
}

.gm-style-iw-d {
    text-align: center;
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;
    
    & p {
        font-size: 13px;
    }
}
