/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

@media only screen and (max-width: 600px) {
    .HomePage {
        .Slider-Arrow {
            inset-block-start: 350px;
        }
        
        .Slider-Arrow_isNext {
            inset-inline-end: 0px !important;
        }

        .Slider-Arrow_isPrev {
            inset-inline-start: 0px !important;
        }

        .Slider-Wrapper {
            height: calc(1.77 * 100vw) !important;
        }
    }

    .HomePage .CmsPage {
        margin-block-start: 0px;
    }
}

.HomePage .Slider {
    padding-block-end: 30px;

    &-Crumbs {
        inset-block-end: 5px;
    }

    &-Crumb {
        box-shadow: none;
        background: #fff;
        border: 1px solid #0f233f;
        width: 10px;
        height: 10px;

        &_isActive {
            background: #0f233f;
        }
    }

    @include mobile {
        padding-block-end: 10px;

        &-Crumbs {
            display: none;
        }
    }
}

.Slider-Wrapper_isVertical ~ .Slider-Crumbs {
    grid-template-columns: unset;
    display: block;
    inset-inline-start: 10px;
    inset-block-start: 55%;
    transform: translate3d(0px,-50%,0);
    width: auto;

    & .Slider-Image {
        margin: 6px 0px;
    }
}
