/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBEnvolverRegalo {
    &-Info, &-Existing {
        margin: 10px 0px;
        color: #666666;
    }

    &-Existing {
        & .YBEnvolverRegalo-Buttons {
            margin-block-start: 10px;
        }
    }

    &-Form {
        & input {
            color: #666666;
        }

        textarea {
            margin: 14px 0 10px; 
        }
    }

    &-Buttons {
        float: inline-end;

        & .Button {
            width: 86px;
            height: 42px;
            font-size: 1.4rem;
            text-transform: capitalize;

            &.Secondary {
                background-color: white;
                border: 1px solid grey;
                color: #666666;
                margin-inline-start: 10px;
            }
        }
    }
}

@media (min-width: 768px) {
    .YBEnvolverRegalo {
        &-Form {
            & input {
                width: 100%;
            }

            textarea {
                min-height: 120px;
                resize: auto;
                width: 100%;
            }
        }

        &-Buttons {
            width: 100%;
        }
    }
}
