/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBRecomendadorTalla {
    text-align: center;

    @include mobile {
        height: max-content;
        inset-block-start: -2px;
    }

    &-Popup {
        .Popup-Content {
            max-width: 80%;
        }

        .Popup-Header {
            margin: 10px 0;
        }
    }

    &-Button {
        color: #ff5a05;
        font-size: 12px;
        font-weight: bolder;
        cursor: pointer;
        margin-block-end: 20px;

        @include mobile {
            font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: white;
            line-height: 16px;
            text-transform: uppercase;
            margin-block-end: 0px;
            background-color: #ff5a05;
            padding: 0 10px;
            max-height: 35px;
            height: 35px;
        }
    }

    &-PopupBody {
        font-family: 'futura-pt-demi', Arial, Helvetica, sans-serif;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        @include desktop {
            min-width: 700px;
        }
    }

    &-PopupImage {
        width: 295px;
        height: 500px;

        img {
            object-fit: contain;
        }

        @include mobile {
            display: none;
        }
    }

    &-PopupContent {
        width: calc(100% - 295px);

        @include desktop {
            max-width: 700px;
            max-height: 500px;
            padding-inline-start: 24px;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-PopupTabs {
        margin: 10px 0;
        display: flex;
        justify-content: center;

        .popupTab {
            width: 50%;
            max-width: 191px;
            cursor: pointer;
            color: #223041;
            background-color: #fff;
            border: 1px solid #223041;
            font-size: 16px;
            padding: 17px 0px;
            line-height: 1.3;
            height: 52px;
            font-weight: bold;
            text-align: center;
        }

        .activePopupTab {
            background: #223041;
            color: #fff;
        }
    }

    &-Content {
        &.guia_tallas {
            max-height: 450px;
            overflow: scroll;
        }
    }

    &-ContentHeading {
        text-align: center;

        .title {
            font-size: 22px;
        }

        .subtitle {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &-GansoIcon {
        display: inline-block;
        width: 30px;
        height: 30px;
        position: relative;
        inset-block-start: 7px;
        inset-inline-end: 10px;
    }

    &-Form {
        .FieldForm {
            &-Fields {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            &-Field {
                width: 50%;
                padding: 12px;

                .label {
                    font-weight: bold;
                    font-size: 12px
                }

                .Field-Wrapper_type_select, .Field-Wrapper_type_number {
                    margin-block-start: 2px;
                }

                .Field_type_select, .Field_type_number {
                    margin: 0;
                    height: 40px;
                }

                select, input {
                    border-radius: 5px;
                    border-color: #aaaaaa;
                    padding-inline-start: 8px;
                }
            }
        }
    }

    &-Buttons {
        text-align: center;
    }

    &-SubmitButton {
        height: 40px;
        margin: 8px;
        font-weight: bold;
        white-space: nowrap;
        font-size: 14px;
        background: #223041;
        color: #ffffff;
        text-align: center;
        text-transform: none;

        &:not([disabled]) {
            &:hover {
                height: 40px;
                background: #223041;
            }
        }
    }

    &-SimpleAdvice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: normal;
        color: #223041;
        background: none;
        max-width: 400px;
        height: 200px;
        line-height: 32px;
        font-size: 16px;

        .ReturnButton {
            width: max-content;
            background-color: #0f233f;
            color: #fff;
            letter-spacing: 1px;
            border: solid 1px #000;
            line-height: 1.3;
            height: 40px;
            font-weight: 600;
            font-size: 16px;
            padding: 10px 14px;
            cursor: pointer;
            margin-block-start: 20px;
        }
    }

    &-ContentResult {
        text-align: center;

        .title {
            font-weight: normal;
            color: #223041;
            background: none;
            height: 32px;
            line-height: 32px;
            font-size: 16px;
        }

        .talla {
            font-size: 80px;
            color: #ff3705;
            height: 96px;
            line-height: 96px;
            font-weight: bolder;

            .Image, img {
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
            }

            .Loader-Main, img {
                min-height: 50px;
            }
        }

        .porcentajes {
            margin: 12px;

            .sizeSlide {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                span {
                    width: fit-content;
                    font-size: 20px;
                    min-width: 35px;

                    &.recommended {
                        color: #ff3705;
                    }
                }

                .application-recommend-step-result-bar {
                    width: calc(100% - 30px);
                    max-width: 230px;
                    margin: 12px;

                    .application-recommend-step-result-bar-percentage {
                        font-size: 15px;
                        text-align: start;
                    }

                    .application-recommend-step-result-bar-space {
                        width: 0;
                        height: 0;
                        border-inline-start: 5px solid transparent;
                        border-inline-end: 5px solid transparent;
                        border-block-start: 5px solid black;
                    }

                    .application-recommend-step-result-bar-line {
                        border-top: solid 2px;
                        width: 100%;
                    }
                }
            }
        }
    }

    &-AddToCart {
        text-align: center;
        margin: 8px;
    }

    &-AddToCartButton {
        text-transform: uppercase;
        background-color: #0f233f;
        color: #fff;
        letter-spacing: 1px;
        border: solid 1px #000;
        line-height: 1.3;
        height: 40px;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 14px;
        cursor: pointer;
    }
    
    &-ChangeData {
        text-align: center;
        margin: 8px;
        margin-block-start: 12px;
    }

    &-ChangeDataButton {
        font-family: 'futura-pt-demi',Arial, Helvetica, sans-serif;
        cursor: pointer;
        color: #223041;
        font-size: 14px;
        letter-spacing: 0px;
        text-decoration: underline;
        border-color: #fff;
        padding: 10px 14px;
        line-height: 1.3;
        height: 40px;
        font-weight: bold;
    }
}
