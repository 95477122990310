/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Popup {
    &-Heading {
        @include desktop {
            font: 18px/22px 'Lato',sans-serif;
            text-transform: uppercase;
            letter-spacing: 5px;
            text-align: center;
            color: #00295a;
            font-weight: 300;
            margin: 20px;
        }

        @include mobile {
            display: none;
        }
    }

    &-Header {
        min-height: 22px;

        @include mobile {
            display: block;
        }
    }

    & &-CloseBtn {
        inset-block-start: 0;
        cursor: pointer;
    }

    &-Content {
        padding-block-start: 0;
    }

    &.StripePayments-ShowError {
        font-family: 'Lato', sans-serif;
        font-size: 16px;

        .Popup-Content {
            max-width: 50%;
            border: 2px solid #032959;

            .Popup-Heading {
                display: block;
                letter-spacing: normal;
                font-weight: bold;

                @include mobile {
                    text-align: center;
                    text-transform: uppercase;
                    margin-block-start: 15px;
                    color: #032959;
                }
            }
        }

        .Popup-StripeError {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .errorIcon {
                width: 40px;
                height: 39px;
                margin-block-start: 10px;
                margin-block-end: 20px;
            }

            .Popup-CloseStripeError {
                margin-block-start: 30px;
            }

            path {
                fill: #032959;
            }
        }
    }
}

.Newsletter-Popup {
    h3.Popup-Heading {
        display: none;
    }
}

.Footer-Popup {
    .Popup-Content {
        @include desktop {
            width: 88%;
            height: 90vh;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .Popup {
            margin-block-start: 45px;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}
