/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBRmaRow {
    margin-block-start: 5px;
    margin-block-end: 15px;
    display: inline-flex;
    height: 130px;
    width: 100%;
    border-bottom: 1px solid #ccc;

    & .fila-rma {
        font-size: 14px;
        color: #223041;
        line-height: 18px;
        margin: auto 0;

        & .bold-label {
            font-weight: bold;
            color: #000;
        }

        & .link-label {
            color: #1979c3;
            cursor: pointer;
        }
    }

    & .fila-rma-image {
        width: 120px;
        height: 95%;
        margin-inline-end: 10px;

        & svg {
            width: 95%;
            height: auto;
        }

        .swiper .swiper-button-prev::after, .swiper .swiper-button-next::after {
            font-size: 10px;
            color: #0f233f !important;            
        }
    }
}
