/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PaypalSmartButtons {
    z-index: 1;
}

.ProductPage {
    .PaypalSmartButtons {
        margin-inline-end: 16px;
        margin-block-start: 16px;
    }
}

.CartOverlay {
    .PaypalSmartButtons {
        margin: 16px;
    }
}

.CartPage {
    .CheckoutOrderSummary {
        &-ButtonWrapper {
            @include mobile {
                height: calc(var(--footer-totals-height) + 52px);
                overflow-y: auto;
            }
        }
    }

    .PaypalSmartButtons {
        margin: 12px 0;
    }
}
