/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBrecogidaEnTienda {
    .Button {
        max-width: 65%;
        max-height: 30px;
        margin-inline-start: 15px;
        font-size: 12px;
        margin-block-start: 15px;
    }

    .storeDescription {
        font-size: 16px;
        font-weight: bold;
        padding-block-start: 10px;
        margin-block-end: 3px;
    }

    .Field_type_text {
        display: none;
    }

    .Field-ErrorMessage {
        color: red;
    }
}

.YBrecogidaEnTienda_modStoreSelected_Store_Selected, .StorePickupMrw_modStoreMrwSelected_Store_Selected {
    .Field-ErrorMessage {
        display: none;
    }
}

.YBstorepickup-Popup {
    .Popup-Heading {
        display: none;
    }

    .Checkout-Heading {
        font: 18px/22px 'Lato', sans-serif;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        color: #00295a;
        text-transform: uppercase;
        border-bottom: none;
    }

    .text {
        font: 14px/17px 'Lato', sans-serif;
        font-weight: 300;
        color: #6A6A6A;
    }

    .Popup-Content .Button {
        margin-block-start: 15px;
    }
    
    .StoresPickUpMap-Map {
        min-height: 600px;
        margin-block-start: 15px;
        -webkit-filter: grayscale(60%);
        -moz-filter: grayscale(60%);
        -ms-filter: grayscale(60%);
        -o-filter: grayscale(60%);
        filter: grayscale(60%);
    }

    .nearbyShops {
        margin-block-start: 30px;
        padding-block-start: 15px;
        border-top: 1px solid #dddddd;

        .radio {
            float: inline-start;
            inset-block-start: -10px;
        }

        ul {
            li {
                height: 24px;
                margin-block-end: 20px;

                &::before {
                    content: none;
                }

                .Field_type_radio {
                    margin-block-start: 8px;
                }
            }
        }
    }
}

@include desktop {
    .YBstorepickup-Popup {
        .Field_type_select {
            width: 50%;
            display: block;
            margin: 24px auto;
        }

        .FieldSelect-Select {
            font-size: 13px;
        }
        
        .Field-Wrapper_type_text {
            input {
                width: 50%;
                display: block;
                margin: 10px auto;
            }
        }

        .Button {
            &:not(.Button_Select_Store) {
                width: 50%;
                display: block;
                margin: 10px auto;
            }
        }
    }
}
