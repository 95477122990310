/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.InvitePointsPage {
    max-width: 800px;
    margin: 30px auto;

    @include mobile {
        padding: 0 14px;
        font-family: "futura-pt-light", sans-serif;
        color: #363b4f;
    }

    .title {
        font: normal normal 300 30px/24px 'Lato',sans-serif;
        text-align: center;
        letter-spacing: 5px;
        text-transform: uppercase;
        margin-block-end: 15px;

        @include mobile {
            font-family: "futura-pt-book", sans-serif;
            font-size: 18px;
            margin: 0 0 20px;
            padding: 10px 0 0;
            color: #363b4f;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: start;
            text-transform: none;
        }
    }

    .descripcion {
        font-size: 16px;
        color: #223041;
        margin: 20px 0;
        text-align: center;
    }

    .shareLink {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 0 40px;

        @include mobile {
            width: 70%;
            margin: 0 auto;
        }

        .share-button {
            width: 64px;
            height: 64px;
            margin: 5px;

            rect {
                fill: #00295a;

                @include mobile {
                    fill: #363b4f;
                }
            }

            &:hover {
                rect {
                    fill: #8f8f8f;
                }
            }
        }

        .button-shareLink {
            max-width: 400px;
            padding: 5px;
            background-color: #00295a;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            border-radius: 2px;
            text-transform: uppercase;

            @include mobile {
                background-color: #363b4f;
            }

            &:hover {
                background-color: #8f8f8f;
            }

            svg {
                height: 45px;
                width: 45px;
            }

            path {
                fill: white;
            }
        }
    }

    .emailLink {
        font-size: 16px;
        color: #223041;
        text-align: center;
        margin: 15px 0 5px;
    }
}
