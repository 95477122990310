/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.AboutUsPage {
    .body-aboutus-green {
        padding: 0 15px;
    }

    & .text-left {
        padding-inline-end: 15px;
        padding-inline-start: 15px;

        &-TextImage {
            @include desktop {
                float: inline-start;
            }
        }

        &-TextPagraf {
            @include desktop {
                width: 75%;
                float: inline-start;
            }
        }

        &-TextPagrafExpecial {
            @include desktop {
                float: inline-start;
                width: 66%;
            }
        }
    }

    .RangeSlider {
        margin: 15px 0;

        &-Info {
            display: flex;
            justify-content: space-between;
            margin-block-start: 16px;
        }

        &-InfoMin, &-InfoMax {
            text-align: center;
            line-height: 1.5rem;
            font-family: 'Merriweather Regular', Arial, Helvetica, sans-serif;
            letter-spacing: unset;
            font-weight: bold;
            color: #081d4c;
            font-size: 1.55rem;
            cursor: pointer;
        }
    }

    #box {
        /*width: calc(100% - 200px);*/
        height: 610px;
        /*background-color:red;*/
        position: absolute;
        inset-block-start: 125px;
        overflow: hidden;
        /*overflow: scroll;*/
        margin: 0px auto;
        inset-inline-start: 0px;
        inset-inline-end: 0px;
        display: flex;
        justify-content: center;
    }

    #difuminado {
        height: 200px;
        background: linear-gradient(to bottom, rgba(256, 256, 256, 0), rgba(256, 256, 256, 0.6), rgba(256, 256, 256, 1));
        position: absolute;
        width: 100%;
        inset-block-start: 410px;
        /*margin: 0 -200px;*/
    }
    
    #holder {
        /*width:999999px;*/
        width: calc(100% - 200px);
        height: 40000px;
        position: absolute;
        /*overflow:hidden;*/
        padding: 0px 100px
    }

    .boxes {
        /*width:400px;*/
        width: 100%;
        /*height:400px;*/
        height: 100px;
        /*display: block;*/
        /*border: 2px solid blue;*/
        /*position:relative;*/
        /*float:left;*/
        // text-align: left;
    }
    
    .boxes img {
        max-height: 200px;
        /*width: 100%;*/
    }

    .year {
        position: relative;
        float: inline-start;
        /* solucion bug safari */
        /*height:13px;
        padding: 0px 24px;*/
        text-align: center;
        line-height: 1.5rem;
        font-family: 'Merriweather Regular', Arial, Helvetica, sans-serif;
        letter-spacing: unset;
        font-weight: bold;
        color: #081d4c;
        font-size: 1.55rem;
        cursor: pointer;
        margin-inline-start: -5px;
    }

    .year:last-child {
        padding-inline-end: 0px;
        margin-inline-end: 0px;
    }

    .year:first-child {
        padding-inline-start: 0px;
        margin-inline-start: -15px;
    }
    
    #yearTrack {
        position: absolute;
        inset-block-start: 50px;
        /*margin:0px;
        padding:0px;*/
        /* solucion bug en safari */
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    #slider {
        width: 35px;
        height: 35px;
        position: absolute;
        inset-inline-start: 0px;
        inset-block-start: 10px;
        z-index: 1;
        cursor: move;
    }

    #slider img {
        width: 100%;
    }

    #timeline-slider {
        position: relative;
        width: 850px;
    }

    #barra-slider {
        position: absolute;
        height: 5px;
        background-color: lightgrey;
        width: 100%;
        inset-block-start: 25px;
        border-radius: 2px;
    }
    
    .body-aboutus-green .fila-3 .contenido {
        width: 100%;
        display: flex;
        height: 800px;
        position: relative;
        justify-content: center;
    }

    .fila-1 .contenido h1 {
        color: #081d4c !important;
        margin-block-start: 40px;
        font: normal normal 300 30px/35px 'Lato', sans-serif;
        letter-spacing: 9px;

        @include desktop {
            text-align: center;
        }
    }

    .fila-2 .contenido h2 {
        color: #081d4c;
        margin-block-start: 20px;
        margin-block-end: 40px;
        font: normal normal 400 30px/24px 'Lato',sans-serif !important;
        letter-spacing: unset !important;

        @include desktop {
            text-align: center;
        }
    }

    .fila-2 .contenido h2 .azulclaro {
        color: #648bcd;
    }

    .fila-2 .contenido h2 .rojo {
        color: #ff0000;
    }
    
    .historia {
        margin-inline-start: auto;
        margin-inline-end: auto;
        display: block;
        margin-block-end: 10px;
    }

    .historia p, .contenido p {
        font: 15px/17px 'Lato', sans-serif;
        font-weight: 300;
        color: #4A4A4A;
        margin-block-start: 20px;
        letter-spacing: unset;
        line-height: 22px;
    }

    .historia h3, .contenido h3 {
        color: #081d4c;
        font: 25px/27px 'Merriweather', serif;
        font-weight: 300;
        letter-spacing: unset;
    }
    
    .fila-4 .contenido {
        background-color: #f0f4fa;
        margin: 0px 100px;
        padding: 0px 100px;
        padding-block-start: 20px;
    }

    .fila-4 .contenido .columna {
        background-color: #f0f4fa;
    }

    .fila-5 .contenido .columna {
        padding: 40px 200px;
    }
    
    .fila-5 .contenido .columna p {
        margin-block-end: 40px;
    }
    
    #y2004.historia {
        height: 425px;
    }

    #y2005.historia {
        height: 325px;
    }

    #y2006.historia {
        height: 350px;
    }

    #y2007-2010.historia {
        height: 350px;
    }

    #y2011.historia {
        height: 450px;
    }

    #y2012-2014.historia {
        height: 490px;
    }

    #y2015-2017.historia {
        height: 325px;
    }

    #y2018-2019.historia {
        height: 300px;
    }

    #y2020.historia {
        height: 570px;
    }

    #y2021.historia {
        height: 350px;
    }

    #y2022.historia {
        height: 250px;
    }

    #y2023.historia {
        height: 400px;
    }

    #ytomorrow.historia {
        height: 200px;
    }
    
    .bodySinScroll {
        overflow-y: hidden !important;
    }
    
    @media (max-width: 770px) {
        .fila-3 h3 {
            padding: 5px 0px;
            margin: 0px !important;
        }
        
        .fila-1>div {
            margin-block-start: 30px;
            min-height: 20px !important;
        }

        .fila-2 .contenido {
            padding: 0px 15px;
        }

        #timeline-slider {
            width: 300px;
        }

        #yearTrack {
            position: relative;
        }

        .year {
            display: none;
        }

        .year:last-child { 
            display: unset; 
            position: absolute;
            inset-inline-end: -20px;
        }

        .year:first-child { 
            display: unset; 
            position: absolute;
            margin-inline-start: 0px;
            inset-inline-start: 0px;
        }

        .fila-4 .contenido {
            padding: 10px 15px;
            margin: 0px;
        }

        .fila-4 .contenido .columna {
            padding-block-end: 25px;
        }

        .fila-5 {
            padding-inline-start: unset;
            padding-inline-end: unset
        }

        .fila-5 .contenido .columna {
            padding: 40px 30px;
        }

        #holder {
            width: calc(100% - 30px);
            padding: 0px;
        }

        .boxes img {
            display: block;
            margin: 0 auto;
        }

        .fila-1 .articulo-box, .fila-2 .articulo-box {
            min-height: unset !important;
        }

        #y2004.historia {
            height: 780px;
        }

        #y2005.historia {
            height: 580px;
        
            @include desktop {
                height: 480px;
            }
        }

        #y2006.historia {
            height: 470px;
        }

        #y2007-2010.historia {
            height: 500px;
        }

        #y2011.historia {
            height: 450px;
        }

        #y2012-2014.historia {
            height: 750px;
        }

        #y2015-2017.historia {
            height: 600px;
        }

        #y2018-2019.historia {
            height: 700px;
        }

        #y2020.historia {
            height: 750px;
        }

        #y2021.historia {
            height: 550px;
        }

        #ytomorrow.historia {
            height: 200px;
        }
    }
}
