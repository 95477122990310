/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Rma, .GuestRma {
    @include desktop {
        padding-block-start: 20px;
    }

    & .YBRma {
        margin-block-start: 30px;
        margin-inline-start: 10px;
        margin-inline-end: 10px;
        padding-block-end: 40px;

        @include desktop {
            max-width: 1400px;
            margin-inline-start: auto;
            margin-inline-end: auto;
        }

        & .YBRma-new-Rma {
            width: 100%;
            height: 50px;
            background-color: #00295a;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
