/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.social_login_container {
    display: grid;    
}

.social_login_title {
    text-align: center;
    margin: 30px 0 20px;
    font-size: 16px;
    color: grey;
    border-bottom: 1px solid grey;
    line-height: 0px;

    span {
        background: white; 
        padding: 0 15px; 
    }
}

.SocialLogin {
    display: inline-flex;
    justify-content: center;

    .social_button {
        button {
            width: 45px;
        }

        .authButton {
            cursor: pointer;
            height: 50px;
            padding: 0px 10px;
            border: 0px;
            border-radius: 3px;
            margin: 5px;
            width: 45px;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
        }
    }
}
