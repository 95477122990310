/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.OutOfStockForm {
    &-Popup {
        .privPolDisabled {
            opacity: 0.5;
        }

        .title_suscripcion {
            color: rgb(0,41,90);
            font: 18px/25px 'Lato', sans-serif;
            text-align: center;
            text-transform: uppercase;
            margin-block-start: 30px;
            margin-block-end: 30px;
            font-weight: 300;
        }

        .Form {
            @include desktop {
                width: 70%;
                margin: auto;
            }

            .Field-Wrapper_type_email {
                .Field-ErrorMessage {
                    text-align: center;
                }
            }

            .Field_type_email {
                text-align: center;
                margin-block-start: 30px;

                .Field-LabelContainer {
                    display: initial;
                    text-align: center;

                    .Field-Label {
                        font: 14px/20px 'Lato',sans-serif;
                        font-weight: 300;
                        color: #4a4a4a;
                    }
                }

                .outofstockform_email {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: solid 1px #979797;
                    width: 80%;
                    display: inline-flex;
                    font-size: 20px;
                    padding: 0 10px;
                    height: 32px;
                }
            }

            .accept-term-continer {
                text-align: center;

                .OutOfStockForm-accept_term_label {
                    display: inline-flex;

                    .Field_type_checkbox {
                        margin-block-start: 0px;
                    }
                }

                .privacy-policy-text {
                    line-height: 16px;
                    font-size: 12px;
                    font-family: 'futura-pt-book', sans-serif;
                    color: rgb(52,64,121);
                    font-weight: 700;

                    .privacy-policy-link {
                        color: rgb(15,35,63);
                    }
                }
            }
        }

        .lista-condiciones-lopd {
            margin-block-start: 30px;

            li {
                font-size: 10px;
                line-height: initial;
                font-family: 'futura-pt-book', sans-serif;
                color: #344079;

                &::before {
                    inset-inline-start: -1em;
                    inset-block-start: 0em;
                }

                .privacy-policy-link {
                    font-size: 10px;
                }
            }
        }

        .OutOfStockForm-Suscribe-Button {
            width: 50%;
            display: block;
            margin: 40px auto 0;
        }

        .container-result-suscripcion {
            margin: 15px 10px;
            text-align: center;
            font-size: 14px;

            .result-suscripcion-ok {
                color: rgb(0,128,0);
            }

            .result-suscripcion-false {
                color: rgb(255,0,0);
            }
        }
    }

    &-sizeField {
        width: 22%;
        border: 1px solid #ccc;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0 auto;
        margin-block-end: 20px;
        color: #344079;
        font-size: 14px;

        #oselect-placeholder {
            display: none;
        }

        .FieldSelect-Option, .FieldSelect-Select {
            color: #344079;
            font-size: 14px;
        }

        path {
            fill: #344079;
        }
    }

    &-sizeTitle {
        display: block;
        font: 18px/25px 'Lato', sans-serif;
        font-weight: 300;
        color: #00295a!important;
        line-height: 1.3;
        padding-block-end: 15px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        margin-block-start: 20px;
    }
}
