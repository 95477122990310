/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    border: none;
    margin-inline-start: 0px;

    & .ChevronIcon {
        margin-block-start: 10px;
    }

    &-Button {
        justify-content: unset;
    }

    &-Heading,
    &-SubHeading {
        display: block;
        padding-inline-end: 10px;
        font-size: 1.8rem;
        color: #333;
        font-weight: 300;
        font-family: Lato, sans-serif;
    }

    &-Heading {
        font-size: 16px;
        padding-block: 4px 0px;
        padding-inline-start: 10px;
        word-break: initial;    
    }

    &-SubHeading {
        font-weight: 400;
        line-height: 1.1;
        font-size: 12px;
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        @include desktop {
            margin-block-start: 0px;
        }

        &_isContentExpanded {
            @include expanded-content;
        }
    }

    &_isWithoutBorder {
        border: 0;

        &:last-of-type {
            border: 0;
        }
    }

    .ExpandableContent-Heading.ProductInformation-ExpandableContentHeading {
        color: #0F233F;
        font-size: 14px !important;
        font-family: futura-pt-demi, sans-serif;

        @include desktop {
            display: block;
        }
    }

    .ProductInformation-ExpandableContentButton {
        .MinusIcon {
            fill: #0F233F;
        }
    }
}

.Menu-Item .ExpandableContent-Heading {
    text-transform: uppercase;
}

.Checkout-Discount .Checkout-ExpandableContentHeading {
    @include mobile {
        padding-inline-start: 0;
    }
}
