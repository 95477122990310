/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBTiendasWholesale {
    &-loc-cabecera p {
        font: 13px/16px 'Lato',sans-serif;
        font-weight: 300;
        text-align: center;
        color: #6a6a6a;
        padding-inline-end: 5px;
        padding-inline-start: 5px;
    }

    &-Form {
        & h1 {
            font: 17px/21px 'Lato',sans-serif;
            font-weight: 300;
            letter-spacing: 5px;
            text-align: center;
            color: #00295a;
            margin-block-end: 2rem;
            margin-block-start: 2rem;
            text-transform: uppercase;
        }

        position: relative;
        display: block;
        padding-inline-end: 30px;
        padding-inline-start: 30px;

        @include desktop {
            padding-inline-start: 35%;
            padding-inline-end: 35%;
        }
    }

    & .FieldSelect-Select {
        & option {
            color: #6a6a6a;
        }

        -webkit-appearance: none;
        color: #6a6a6a;
        background-color: #fff;
        border-color: #ccc;
        padding: 6px 12px;
    }

    & .FieldSelect-Clickable {
        border-radius: 0;
        margin-block-end: 20px;
        height: 34px;
    }

    &-LocateButton button {
        width: 100%;
        border-radius: 0;
        border: solid 1px #00295a!important;
        background-color: #00295a;
        color: #fff;
        font: 15px/17px 'Lato', sans-serif;
        font-weight: 300;
        padding: 10px;
        text-transform: capitalize;
        margin: 2rem 0 3rem;
        height: 40px;

        &:hover {
            height: 40px !important;
        }
    }

    &-Tienda {
        font-family: 'Lato', sans-serif !important;
        font-size: 14px;
        color: #223041;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-end: 20px;    

        .nombre {
            font-weight: bold;
        }
    }
}
