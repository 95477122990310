/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

.MySubscription {
    &-Billed {
        opacity: .5;
        font-size: 1rem;
        margin-block-end: 0.5rem;
    }

    &-Option,
    &-Payment {
        display: flex;
        align-items: center;
    }

    &-Payment {
        .Button {
            font-size: inherit;
            margin-inline-start: .5rem;
        }

        img {
            width: 20px;
            margin-inline-end: .5rem;
        }
    }

    &-Option {
        min-height: 60px;
        cursor: pointer;

        .Field {
            margin-block-start: 0;
        }

        img {
            width: 40px;
            margin-inline-end: 15px;
        }
    }

    &-Button {
        margin-block-start: 1rem;
    }
}
