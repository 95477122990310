/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.CmsPage-Content .pagebuilder-mobile-only, .CmsBlock-Wrapper .pagebuilder-mobile-only, .css-pwa {
    display: none;
}

.svg-inline--fa {
    width: 23px;
    height: 22px;
}

.svg-inline--fa path {
    fill: #223041;
}

@media screen and (max-width: 810px) {
    .CmsPage-Content .pagebuilder-mobile-only, .CmsBlock-Wrapper .pagebuilder-mobile-only {
        display: block;
    }

    .CmsPage-Content .pagebuilder-mobile-hidden, .CmsBlock-Wrapper .pagebuilder-mobile-hidden {
        display: none;
    }

    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
}


.pagebuilder-column {
    background-attachment: scroll !important;
    flex-basis: 100%;
    max-width: 100%;
}

.CmsBlock {
    &-Wrapper {
        div.collapse {
            display: none;
            overflow: hidden;
        }

        div.panel-group.gnt-accordion.envios p {
            margin-block-end: 10px !important;
        }

        .text-center {
            text-align: center;
        }
    }
}

.CmsBlock-Wrapper.product_delivery {
    // NO TIENE UN COMPONENTE EN ESPECIFICO Y DAMOS DISEÑO AQUI
    margin-block-start: 20px;
    padding: 0 15px;

    div.collapsed {
        font-family: 'Lato', sans-serif;
        text-align: center;
        letter-spacing: 5px;

        h4 {
            cursor: pointer;
            padding: 10px 20px;
            border-bottom: solid 1px #00295a;
            border-top: solid 1px #00295a;
            margin-block-end: 25px;
        }

        a.accordion-toggle {
            font-size: 18px;
            line-height: 20px;
            font-weight: 300;
            color: #344079;
        }
    }

    .panel-default {
        p {
            font-size: 14px;
            line-height: 17px;
            font-weight: 300;
            color: #6a6a6a;
            font-family: 'Lato', sans-serif;
        }
        
        p.tit {
            font-weight: 400;
            color: #00295a;
        }

        p.aviso {
            letter-spacing: 4px;
            font-size: 13px;
            line-height: 18px;
            color: #00295a;
            text-align: center;
        }

        table.shipping_time {
            width: 100%;
        }

        a {
            color: #344079;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            font-size: 12px;
        }

        ol.lista_env_dev {
            padding-inline-start: 15px;
        }

        li::before {
            position: relative;
            inset-inline-start: 0;
            padding-inline-end: 10px;
        }
    }
}

.CmsBlock-Wrapper.faq {
    // PONEMOS LOS ESTILOS DE LAS FAQ EN SU COMPONENTE 
    // PARA NO SOBRECARGAR EL COMPONENTE CMSBLOCK 
    // Y TENERLO TODO LO RELACIONADO CON LAS FAQ EN SU COMPONENTE
}
