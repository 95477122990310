/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBRma {
    @include desktop {
        max-width: 800px !important;
    }

    &-InfoContainer {
        margin-block-end: 40px;

        p, span {
            font-family: "futura-pt-light", sans-serif;
            line-height: normal;
            color: #363b4f;
            font-size: 16px;
        }
    }

    &-AdviceInfo {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        margin-block-end: 20px;
    }

    &-BlockInfo {
        margin-block-end: 20px;

        .link {
            font-family: "futura-pt-demi", sans-serif;
            text-decoration: underline;
            margin: 20px 0;
            cursor: pointer;
        }
    }

    & .back-link {
        width: 100%;
        height: 30px;

        & .back-link-label {
            color: #1979c3;
            font-size: 14px;
            float: inline-end;
            cursor: pointer;
        }
    }

    & .detalles-rma-row {
        font-size: 14px;
        border-bottom: 1px solid #ccc;
        height: 30px;
        margin-block-start: 20px;

        & .detalles-rma-title {
            font-size: 20px;
            float: inline-start;
        }

        & .detalles-rma-date {
            float: inline-end;
            line-height: 30px;
        }
    }

    & .detalles-rma {
        padding-block-start: 20px;

        & .detalles-rma-fila {
            width: 100%;
            display: inline-flex;

            & .detalles-col {
                width: 50%;

                & .title {
                    color: #344079;
                    width: 80%;
                    border-bottom: 1px solid #ccc;
                    font-weight: bold;
                    line-height: 20px;

                    @include desktop {
                        width: 95%;
                        margin: 0 2.5% 14px;
                    }
                }

                & .content {
                    font-size: 13px;

                    @include desktop {
                        width: 95%;
                        margin: 0 2.5% 14px;
                    }

                    & .link-label {
                        color: #1979c3;
                        cursor: pointer;
                    }
                    
                    & .label-desc {
                        font-style: italic;
                        font-weight: bold;
                    }
                }
            }

            & .detalles-col-product {
                & .label-desc {
                    font-weight: bold;
                }
            }

            & .col-xl-right {
                width: 35%;
                text-align: start;
            }

            & .col-left {
                width: 10%;
                text-align: end;
            }
        }
    }

    &-listRma {
        margin-block-start: 10px;
    }

    &-emptyListRma {
        margin-block-start: 50px;
        text-align: center;
        font-size: 14px;
        color: #00295a;
    }

    & .payment_method {
        & .FieldRadio-label {
            max-width: 80px;
        }

        & .ybrmapay-row {
            width: 100%;
            text-align: center;
            margin-block-start: 15px;
            margin-block-end: 15px;
        }

        & .YBRmaPay-Button {
            height: 40px;
            background-color: #00295a;
            color: #fff;
            font-size: 18px;
            padding: 0 5%;
            cursor: pointer;
        }

        & .error-msg {
            color: red;
        }
    }
}
