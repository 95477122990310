/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
@media (max-width: 1300px) and (min-width: 768px) {
    .YBImageZoomPopup {
        .swiper-button-prev {
            height: 30px !important;
            width: 30px !important;
        }

        .swiper-button-next {
            height: 30px !important;
            width: 30px !important;
        }

        .Popup-Header {
            inset-block-end: 90px !important;

            .Popup-CloseBtn {
                width: 33px !important;
                height: 33px !important;
                inset-inline-end: 9px !important;
            }
        }
    }
}

@media (min-width: 2500px) {
    .YBImageZoomPopup {
        .swiper-button-prev, .swiper-button-next {
            height: 80px !important;
            width: 80px !important;

            &::after {
                font-size: 26px !important;
            }
        }

        .Popup-Header {
            inset-block-end: 140px !important;

            .Popup-CloseBtn {
                width: 83px !important;
                height: 83px !important;
                inset-inline-end: 22px !important;

                svg {
                    width: 34px !important;
                    height: 34px !important;
                }
            }
        }
    }
}

.YBImageZoomPopup {
    .Popup-Header {
        position: absolute;
        inset-block-end: 105px;
        inset-inline-start: 0px;
        inset-inline-start: 53%;

        .Popup-CloseBtn {
            width: 48px;
            height: 48px;
            border: none;
            border-radius: 50%;
            background: white;
            color: #1c1b1b;
            cursor: pointer;
            text-align: center;
            
            --webkit-box-shadow: 0 2px 10px rgb(54 54 54 / 15%);

            box-shadow: 0 2px 10px #36363626;
            vertical-align: middle;
            line-height: 0;
            transform: scale(1.001);
            
            --webkit-transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;

            transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            overflow: hidden;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &-box {
        display: block;
        
        img {
            height: 100vh;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
        }

        .swiper-button-prev {
            inset-block-end: 50px;
            inset-inline-start: inherit;
            inset-block-start: inherit;
            inset-inline-end: 52%;
            width: 45px;
            height: 45px;
            border: none;
            border-radius: 50%;
            background: white;
            color: #1c1b1b;
            cursor: pointer;
            text-align: center;
            z-index: 1;

            --webkit-box-shadow: 0 2px 10px rgb(54 54 54 / 15%);

            box-shadow: 0 2px 10px #36363626;
            vertical-align: middle;
            line-height: 0;
            transform: scale(1.001);

            --webkit-transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;

            transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            overflow: hidden;

            &::after {
                font-size: 16px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        .swiper-button-next {
            inset-block-end: 50px;
            inset-inline-start: inherit;
            inset-block-start: inherit;
            inset-inline-end: 44%;
            width: 45px;
            height: 45px;
            border: none;
            border-radius: 50%;
            background: white;
            color: #1c1b1b;
            cursor: pointer;
            text-align: center;
            z-index: 1;

            --webkit-box-shadow: 0 2px 10px rgb(54 54 54 / 15%);

            box-shadow: 0 2px 10px #36363626;
            vertical-align: middle;
            line-height: 0;
            transform: scale(1.001);

            --webkit-transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            
            transition: background .15s ease-in-out,opacity .15s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out;
            transition: background .15s ease-in-out,opacity .15s ease-in-out,transform .2s ease-in-out,color .2s ease-in-out,transform .2s ease-in-out;
            overflow: hidden;

            &::after {
                font-size: 16px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &.Popup {
        height: 100%;
    }
    
    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;
        overflow: hidden;

        .Popup-CloseBtn {
            inset-inline-end: 14px;
            inset-block-start: 10px;
        }
    }

    .YBProductGallery-ImgBlock {
        height: 100%;
        display: block;
        width: 60%;
        margin-block-start: 50px;
        margin: 0 auto;

        &-SliderWrapper {
            height: 100%;
        }

        img {
            cursor: zoom-in;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
