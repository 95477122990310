/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

:root {
    --footer-nav-height: 42px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    margin-block-end: 0;

    &-Content {
        min-height: 0;
        background-color: white;

        .footer_pwa {
            @include desktop {
                display: flex;
                justify-content: space-between;
            
                .columna_izquierda ul {
                    justify-content: flex-start !important;
                }

                .columna_derecha ul {
                    justify-content: flex-end !important;
                }
            }

            ul {
                display: flex;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;

                li a {
                    font: 10px/13px 'Lato', sans-serif;
                    font-weight: bold;
                    color: #4a4a4a;
                    text-transform: uppercase;

                    @include desktop {
                        font: 14px/20px 'futura-pt-light', sans-serif;
                        font-weight: bold;
                        color: #0f233f;
                    }
                }

                li::before {
                    content: "|";
                    position: initial;
                    padding: 0 5px;
                    font-size: 16px;
                    font-weight: 100;
                }

                li:first-child::before {
                    content: '';
                }
            }
        }
    }

    &-Columns {
        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 18px 16px;
            width: 100%;
        }

        padding: 0px 0px 20px;
        max-width: 100%;
        flex-direction: column;

        .Footer-Column_isNewsletter {
            width: 100%;
            max-width: 100%;
            padding: 20px 25% !important;
            margin-block-end: 0;

            @include mobile {
                padding: 20px 0 !important;
            }

            .Footer-ColumnTitle, .NewsletterSubscription-title {
                display: none;
            }

            .NewsletterSubscription-body {
                margin-block-start: 0;
            }
        }
    }

    .CmsBlock-Wrapper {
        width: 100%;

        @include desktop {
            padding: 0 15px;
        }

        > div {
            width: 100%;
        }
    }

    &-SocialIcons {
        display: flex;
        justify-content: center;
        padding-block-end: 25px;

        li::before {
            display: none;
        }

        li, li:last-child {
            margin-block-end: 6px;
        }

        .Image {
            width: 30px;
            margin: 0 5px;
        }
    }

    &-ContainerLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block-end: 25px;

        .LogoGanso {
            width: 50px;
        }

        .TituloGanso {
            width: 120px;
            margin-inline-start: 10px;
        }
    }
}

.FooterEmpy {
    display: none;
    width: 100%;
    height: 50px;
}

.title-newsletter {
    padding-block-start: 50px;
    width: 100%;
}

.title-newsletter p {
    font: 24px 'futura-pt-heavy', sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    color: #223041;
    max-width: 1000px;
    margin: 0 auto;
}

.text-newsletter-bold {
    font: 18px 'futura-pt-heavy', sans-serif;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .Footer {
            margin-block-end: 50px;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}

/* Tablet */
@media (min-width: 768px) {
    // TODO style the element
}
