/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.CmsCategoriesPage {
    .roundImage {
        width: 80px;
        margin: 5px auto;
        
        img {
            border-radius: 50%;
            height: 80px;
            width: 80px;
            border: 2px solid red;
        }
    }

    @include desktop {
        .cms-categories-pwa .pagebuilder-mobile-only {
            display: none;
        }
    }

    @include mobile {
        .cms-categories-pwa .pagebuilder-mobile-hidden {
            display: none;
        }
    }
}
