/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.BlogHorarios {
    @include desktop {
        padding-block-start: 30px;
    }

    &-Heading {
        @include mobile {
            display: block;
            font: 20px/26px "Lato",sans-serif !important;
            margin-block-end: 20px;
            padding: 5px 0;
            color: #344079;
            text-transform: uppercase;
            border-bottom: 1px solid #344079;
        }

        @include desktop {
            font: normal normal 300 30px/24px 'Lato',sans-serif !important;
            letter-spacing: 9px !important;
            text-align: center !important;
            text-transform: uppercase;
        }
    }

    &-Column {
        @include desktop {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 10%;
            padding-block-start: 20px;

            :first-child {
                width: 100%;
                margin: 0 auto;

                a {
                    width: 45%;
                }
            }
        }
    }

    &-Post {
        @include desktop {
            width: 45%;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-block-end: 25px;

            @include desktop {
                display: flex;
                flex-direction: row;
            }
        }

        .Image {
            width: 50%;
            height: 50%;

            @include desktop {
                margin-inline-end: 5%;
            }
        }

        .postInfo {
            display: flex;
            flex-direction: column;
            text-align: center;

            @include desktop {
                text-align: start;
            }

            .title {
                font: 31px/35px 'Merriweather',serif;
                font-weight: 300;
                color: #3C7BB5;
                letter-spacing: 0;
                margin: 0 0 20px;

                @include desktop {
                    font: 28px/35px 'Merriweather',serif;
                }
            }

            .link-content {
                font: 300 22px/28px "Lato",sans-serif;
                color: #3C7BB5;
                letter-spacing: 2px;
                margin-block-end: 20px;
            }
        }
    }
}
