/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBrecogidaEnTiendaMRW {
    .Button {
        max-width: 60%;
        max-height: 30px;
        margin-inline-start: 15px;
        font-size: 12px;
    }

    .storeDescription {
        font-size: 16px;
        font-weight: bold;
        padding-block-start: 10px;
        margin-block-end: 3px;
    }
}

.StorePickupMrw-Popup {
    .Checkout-Heading {
        font: 18px/22px 'Lato', sans-serif;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        color: #00295a;
        text-transform: uppercase;
    }

    .text {
        font: 14px/17px 'Lato', sans-serif;
        font-weight: 300;
        color: #6A6A6A;
    }

    .Popup-Content .Button {
        margin-block-start: 15px;
    }

    .StoresPickUpMrwMap-Map {
        min-height: 600px;
        margin-block-start: 15px;
        -webkit-filter: grayscale(60%);
        -moz-filter: grayscale(60%);
        -ms-filter: grayscale(60%);
        -o-filter: grayscale(60%);
        filter: grayscale(60%);
    }

    .nearbyShops {
        margin-block-start: 30px;
        padding-block-start: 15px;
        border-top: 1px solid #dddddd;

        .radio {
            float: inline-start;
            inset-block-start: -10px;
        }
    }
}
