/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBFraseHead {
    width: 100%;
    height: min-content;
    min-height: 20px;
    display: inline-flex;
    
    .swiper {
        width: 95%;
    }

    .botonCerrar {
        display: block;
        align-items: center;
        width: 5%;

        & .faXmark {
            width: 16px;
            height: 16px;
            float: inline-end;
    
            & path {
                fill: var(--close-icon-color);
            }
        }
    }

    .swiper-backface-hidden .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            @include mobile {
                font-size: 9px !important;
            }
        }
    }
}
