/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ContactPage {
    @include desktop {
        padding-block-start: 20px;
    }

    section {
        padding-block-end: 25px;
    }

    &-Heading {
        @include mobile {
            display: block;
            font: 20px/26px "Lato",sans-serif !important;
            margin: 20px 0px;
            padding: 5px 0;
            color: #344079;
            text-transform: uppercase;
            border-bottom: 1px solid #344079;
        }

        @include desktop {
            font: normal normal 300 17px/21px 'Lato',sans-serif !important;
            letter-spacing: 9px !important;
            text-align: center !important;
            text-transform: uppercase;
        }
    }

    &-ColumnWrapper {
        padding: 0;

        @include desktop {
            display: flex;
            flex-direction: column;
        }
    }

    &-EnviosDevoluciones {
        padding: 0px 15px;
        margin: 40px 0px;

        @include desktop {
            float: inline-start;
            width: 34%;

            button {
                width: 100%;
                font: 15px/17px 'Lato',sans-serif;
                font-weight: 300;
            }
        }
    }

    &-Column {
        padding: 0px 15px;
        margin-block-end: 5px;

        @include desktop {
            float: inline-start;
            width: 32%;
        }

        .row .col-md-4 {
            width: 100%;
        }
    }

    &-InfoEnviosDevoluciones {
        font-family: 'Lato',sans-serif !important;
        font-weight: 300;
        text-align: center;

        .tituloEnvios {
            color: #00295a;
            text-transform: uppercase;
            font: 13px/1.8 'Lato',sans-serif;
            font-weight: bold;
            letter-spacing: 4px;
        }

        .descripcionEnvios {
            color: #223041;
            margin-block-end: 25px;
            line-height: 22px;
            font-weight: 300;
            font-size: 14px;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
        }
    }

    &-Column_isContent {
        @include desktop {
            font: 13px/23px'Lato',sans-serif;
            font-weight: 300;
            letter-spacing: 4px !important;

            .atencion-cliente {
                p, .text {
                    color: #00295a;
                }

                .tit.uppercase {
                    text-transform: uppercase;
                }

                .text {
                    margin-block-end: 5px;
                    padding-block-start: 10px;

                    span {
                        font: 17px/23px 'Lato',sans-serif;
                        font-weight: 400;
                        letter-spacing: 5px;
                        color: #00295a;
                    }
                }
            }

            .ganso-info {
                padding-block-start: 10px;
                margin: 25px 0;
                border-top: 1px solid #344079;
                font: 13px/23px 'Lato',sans-serif;
                font-weight: 300;
                letter-spacing: 4px;

                p, .text {
                    color: #00295a;
                }

                .grey {
                    color: #797979;
                }
            }
        }

        @include mobile {
            font-family: "futura-pt-light", sans-serif;
            margin-block-end: 40px;
            font-weight: normal;
            
            p {
                color: #363b4f;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: normal;
            }

            .text-center {
                text-align: start;
            }

            .atencion-cliente {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .text {
                    margin-block-end: 0;
                    padding-block-start: 0;
                }

                .info-container {
                    width: 50%;
                }

                .tit, .subtit {
                    font-family: "futura-pt-demi", sans-serif;
                }

                .tit {
                    width: 100%;
                    font-size: 18px;
                    margin: 40px 0 10px;

                    b {
                        font-weight: normal;
                    }
                }

                .full {
                    width: 100%;
                    margin-block-start: 10px;
                }
            }
        }
    }

    .FaqsPage-Heading {
        @include mobile {
            font-family: "futura-pt-book", sans-serif;
            margin-block-end: 0;
            padding: 10px 0 0;
            color: #363b4f;
            font-weight: 600;
            letter-spacing: 1px;
        }
    }
    
    .Form {
        .Button {
            @include mobile {
                width: 50%;
            }
        }
    }
}
