/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBAsesoramientoPage {
    &-ybIframe {
        display: block;
        min-height: 700px;
        height: 100%;
        width: 80%;
        margin: 50px auto;
    }

    .loading {
        width: 20px;
        height: 20px;
        position: absolute;
        inset-inline-start: 50%;
        z-index: -1;
    }
    
    .modal-header, .modal-body {
        text-align: center;
        margin-block-start: 10px;
    }
}
