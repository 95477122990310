/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.NavigationTabs {
    border-block-start: 1px solid var(--primary-divider-color);
    background-color: #fff;
    z-index: 802;

    @include desktop {
        display: block;
    }

    &-Nav {
        padding: 0px;
    }

    &-ButtonContainer {
        margin-block-start: 2px;

        .svg-inline--fa {
            width: 20px;
            height: 18px;
        }

        img {
            width: 20px;
            height: auto;
        }
    }

    &-NumberNotifications {
        width: 15px;
        height: 15px;
        background: red;
        border-radius: 94%;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 14px;
        font-weight: 600;
        position: absolute;
        inset-inline-end: -10px;
        inset-block-end: 0;
        padding: 2px;
    }

    &-NumberNotifications.hidePoint {
        display: none !important;
    }

    .heartIcon {
        position: absolute;
        width: 14px;
        height: 13px;
        background-color: #fff;
        inset-inline-start: 42%;
        inset-block-end: 0;
    }

    &-Icon {
        margin: 0 auto;
    }

    &-ButtonTitle {
        font-size: 10px;
        padding-block-start: 2px;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .NavigationTabs {
            height: auto;
            padding-block-end: 0;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}
