/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.GuestRma {
    & .Form {
        margin: 40px auto;
        max-width: 550px;
        width: 90%;

        & .YBGuestRmaLogin-rma-guest-login-Button {
            margin-block-start: 15px;
            background-color: #00295A;
            height: 50px;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            font-weight: 300;
            width: 100%;
        }

        & .Field-LabelContainer {
            padding-block-end: 0px;
            color: #00295a;
            font-weight: 700;

            & .Field-Label {
                font-size: 14px;
            }
        }

        & input {
            padding-block-start: 10px;
            padding-block-end: 10px;
            font-size: 16px;
            margin-block-end: 15px;
        }
    }
}
