/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.MenuPageSub {
    display: block;
    position: relative;
    inset-block-end: 0px;
    width: 100%;
    background-color: white;

    @include mobile {
        inset-block-end: 15px;
    }

    & .SearchField {
        width: 87vw;
        position: relative;
        float: inline-start;
    }

    & .scanIconButton {
        position: relative;
        margin-block-start: 21px;
        width: 8%;
    }

    & .scanIconButton .svg-inline--fa {
        width: 32px;
        height: 25px;
    }

    & .SearchField-Input {
        border-radius: 5px!important;
        background-color: #f5f5f5;
        height: 35px;
        border-bottom: none;
    }
}

.Menu-MenuWrapper {
    height: 80vh;
    overflow-y: scroll;
    padding: 10px 20px;

    @include mobile {
        height: 75vh;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        html {
            overflow: hidden;
        }

        .MenuPageSub {
            inset-block-start: -50px;
        }

        .Menu-MenuWrapper {
            height: 75vh;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        .MenuPageSub {
            .SearchField {
                width: 85vw;
                margin-inline-start: 15px;
            }

            .scanIconButton {
                margin-inline-end: 20px;
                float: inline-end;
            }
        }
    }
}

/* Tablet */
@media (min-width: 768px) {
    .MenuPageSub {
        .SearchField {
            width: 85vw;
            margin-inline-start: 15px;
        }

        .scanIconButton {
            margin-inline-end: 20px;
            float: inline-end;
        }
    }
}

@media screen and (max-device-width: 767px) and (orientation: landscape) {
    .Menu-MenuWrapper {
        height: auto;
        overflow: hidden;
        padding: 10px 20px;
    }
}
