/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBPopupBuilder {
    &-Popup {
        .Popup-Content {
            width: 700px;
            max-height: 95%;
        }

        .magezon-builder .webforms:not(.active), .magezon-builder .messages {
            display: none;
        }

        .NewsletterSubscription-body {
            margin-block-start: 0px;
        }

        @include desktop {
            .mgz-hidden-xl, .mgz-hidden-lg, .mgz-hidden-md {
                display: none;
            }
        }

        @include mobile {
            .mgz-hidden-xs {
                display: none;
            }
        }
        
        .Popup-CloseBtn {
            padding-block-start: 5px;
        }
    }
}
