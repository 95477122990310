/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Router {
    &-MainItems {
        height: calc(100vh - 40px);
        min-height: 0;
        overflow-y: scroll;
        overflow-x: hidden;

        @include mobile {
            &.isApp {
                height: calc(100vh - 88px);
            }

            &:not(.isApp) {
                inset-block-start: 40px;
            }
        }

        main {
            min-height: 80%;
            margin-block-start: 0;
            margin-block-end: 30px;

            @include desktop {
                min-height: 55vh;
            }
        }

        > div {
            min-height: 83%;
            
            @include desktop {
                min-height: 55vh;
            }
        }

        @include desktop {
            .YBProductListPage {
                margin-block-start: 35px;
            }

            main.MyAccount, div.NewRma {
                padding-block-start: 20px;
            }
        }

        main.MenuPage {
            min-height: 100%;
            height: calc(100vh - 88px);
        }
    }
}

.elganso-app-banner > div {
    inset-block-start: 60px;
    position: fixed;

    @include desktop {   
        width: calc(100% - 10px);
    }

    a {
        color: #0f233f;

        &:hover {
            color: rgb(52, 64, 121);
        }
    }
}

body, html {
    overflow-y: hidden;
    height: 100%;
}

body {
    inset-block-start: env(safe-area-inset-top);
    inset-block-end: env(safe-area-inset-bottom);
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .Router {
            &-MainItems {
                height: 91vh;
                
                main {
                    min-height: 76%;
                }

                main.MenuPage {
                    min-height: 0%;
                    height: auto;
                }

                &:not(.isApp) {
                    inset-block-start: 0;
                }
                
                &.isApp {
                    height: calc(100vh - 135px);
                }
            }
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}

/* Tablet */
@media (min-width: 768px) {
    .Router {
        &-MainItems {
            height: 92vh;
        }
    }
}

@media (min-width: 768px) {
    body {
        overflow-y: scroll;
        height: 100vh;
    }

    .Router-MainItems {
        margin-block-start: 0px;
        overflow-x: hidden;
        height: auto;
        // inset-block-start: 0px;
    }

    .Breadcrumbs {
        display: none;
        inset-block-start: 80px;
        height: 30px;
        z-index: 1;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #dcdbdc #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 10px;
        z-index: 1000;
    }

    *::-webkit-scrollbar-track {
        background: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #dcdbdc;
        border-radius: 10px;
        border: 0px solid #ffffff;
    }
}
