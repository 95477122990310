/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Field {
    &-CheckboxLabel {
        font-family: Lato, sans-serif;

        .input-control {
            width: 15px !important;
            height: 15px !important;
            min-width: 10px;
            min-height: 10px;
            border: 1px solid #223055 !important;
            border-radius: 3px !important;
        }
    }
}
