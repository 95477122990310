/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Menu {
    li * {
        color: inherit !important;
    }

    &-Padres {
        margin-block-end: 20px;

        #categoriasHijasPadres {
            @include desktop {
                padding: 0 15px;
            }

            .oculta {
                display: none !important;
            }
        }

        .left {
            @include desktop {
                position: absolute;
                inset-inline-start: 0;
                inset-block-start: 0;
            }
        }

        .right {
            @include desktop {
                position: absolute;
                inset-inline-end: 0;
                inset-block-start: 0;
            }
        }
    }

    &-ImagesPadres {
        padding: 0 15px;

        @include mobile {
            padding: 0;
        }

        .Menu-Link {
            margin: 0;
        }

        .oculta {
            display: none;
        }
        
        .mostrando {
            display: block;
        }
    }

    &-ItemList.CatPadres {
        // Ocultar barra scroll
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        //Hacer scroll lateral 
        white-space: nowrap;
        overflow: auto;

        li {
            display: inline-block;
            padding-block-end: 0;
            margin-inline-end: 20px;
        }

        li.mostrando {
            border-bottom: 1px solid #0d233f;
            
            figcaption {
                font-weight: bold;
            }
        }

        li.mostrando.menu-red, li.mostrando.menu-red-500 {
            border-bottom: 1px solid red;
        }

        div {
            @include desktop {
                width: auto !important;
            }

            .Menu-Item {
                margin: 0 24px 0 0;
            }

            padding-block-end: 0;
        }

        div.mostrando {
            figcaption {
                font-weight: bold;
            }

            figcaption::after {
                border-bottom: 1px solid #0d233f;
                margin: 0 auto;
                content: "";
                display: block;
                width: 4px;
            }
        }

        div.mostrando.menu-red figcaption::after, div.mostrando.menu-red-500 figcaption::after {
            border-bottom: 1px solid red;
            margin: 0 auto;
            content: "";
            display: block;
            width: 4px;
        }

        .swiper {
            @include desktop {
                padding: 0 15px;
            }
        }

        .swiper-slide {
            width: max-content;
        }

        .swiper-button-prev {
            inset-inline-start: 0px;

            @include mobile {
                inset-inline-start: -10px;
            }
        }

        .swiper-button-next {
            inset-inline-end: 0px;

            @include mobile {
                inset-inline-end: -10px;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            color: #223041;
            width: 30px !important;
            background-color: white;
            margin-inline-end: 0;
            inset-block-start: 40%;
        }
        
        .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
            display: none;
        }

        .swiper-button-prev::after, .swiper-button-next::after {
            font-size: 15px;
        }
    }

    &-ItemList.HijasPadres {
        padding-block-start: 10px;
    }

    &-ItemCaption {
        padding: 3px 0;
        font-weight: 400;
        margin: 0;
        line-height: 20px;
        font-size: 16px;
        color: #0d233f;
        cursor: pointer;

        @include mobile {
            font-size: 15px;
            font-weight: 800;
        }

        .menu_etiqueta_text_categoria {
            font-size: 10px;
            position: relative;
            inset-block-end: 8px;
            padding-inline-start: 4px;
            text-transform: uppercase;
            color: inherit !important;
        }

        .menu_etiqueta_text_categoria_oculta {
            display: none;
        }

        &.activeOption {
            font-weight: bold;
        }

        &_isExpanded {
            font-weight: bold;
        }
        
        img {
            max-height: 15px;
        }
    }

    &-SubMenu_isVisible {
        background: white;
    }

    &-ItemCaption:hover {
        color: var(--color-black);
    }

    // ESTILOS CONFIGURABLES DESDE ADMIN
    &-ItemList_type_secondary {
        margin-block-start: 50px;
    }

    &-Item_item_class_menu-red .Menu-ItemCaption_type_main {
        color: red !important;
    }

    &-ItemList_item_class_menu-red .Menu-ItemCaption {
        color: red !important;
    }

    &-Item_item_class_menu-red-500 .Menu-ItemCaption_type_main {
        color: red !important;
        font-weight: 500;
    }

    &-ItemList_item_class_menu-red-500 .Menu-ItemCaption {
        color: red !important;
        font-weight: 500;
    }

    &-ItemList_item_class_menu-spacer-xxs {
        padding-block-end: 10px;
    }

    &-Item_item_class_menu-spacer-xxs {
        padding-block-end: 10px;
    }
    
    &-ItemList_item_class_menu-spacer-xs {
        padding-block-end: 15px;
    }

    &-Item_item_class_menu-spacer-xs {
        padding-block-end: 15px;
    }

    &-ItemList_item_class_menu-spacer-sm {
        padding-block-end: 50px;
    }

    &-Item_item_class_menu-spacer-sm {
        padding-block-end: 50px;
    }

    &-ItemList_item_class_menu-spacer-md {
        padding-block-end: 80px;
    }

    &-Item_item_class_menu-spacer-md {
        padding-block-end: 80px;
    }

    &-ItemList_item_class_menu-spacer-lg {
        padding-block-end: 100px;
    }

    &-Item_item_class_menu-spacer-lg {
        padding-block-end: 100px;
    }

    &-ItemList_item_class_menu-spacer-xlg {
        padding-block-end: 150px;
    }

    &-Item_item_class_menu-spacer-xlg {
        padding-block-end: 150px;
    }

    //Estilos menu escritorio
    &-ExpandedState {
        @include desktop {
            display: unset;
        }
    }

    &-Item {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 20px;

        @include desktop {
            height: unset;
            margin: unset;
        }

        &List {
            @include desktop {
                display: block;
                cursor: pointer;

                .Menu-SubMenu & {
                    display: unset;
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: unset;
            }
        }

        &.activeOption {
            .ExpandableContent-Heading figcaption {
                font-weight: bold;
            }
            
            &.menu-abierto {
                .ExpandableContent-Heading figcaption {
                    font-weight: normal;
                }
            }
        }
    }

    &-NoPadres {
        @include desktop {
            padding: 0 15px;
        }
    }

    &-MainCategories {
        .Menu-ItemCaption_type_main {
            font-weight: bold !important;
        }

        @include desktop {
            padding: 0 15px;
            width: unset;

            .Menu-Link {
                font-weight: unset;
            }
        }
    }

    &-MenuWrapper {
        @include desktop {
            margin-block-start: 60px;
            padding: 0 20px !important;
            
            .Menu-Item .Menu-Item, .Menu-Item .Menu-Item .Menu-ItemCaption, .Menu-MainCategories .Menu-SubMenu .Menu-ItemCaption {
                text-transform: none;
                font-size: 15px;
                line-height: 18px;
            }
        }

        &.Original {
            .Menu-ItemCaption {
                @include desktop {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .Menu-Padres {
                margin-block-end: 0;

                .CatPadres {
                    display: flex;
                    flex-direction: column;
                    
                    @include desktop {
                        padding: 0 15px;
                    }

                    .SubMenu-Expandable ul {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: unset;
            padding-block-end: unset;
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                @include desktop {
                    font-weight: unset;
                }
            }
        }

        &Menu {
            @include desktop {
                display: none;


                &_isVisible {
                    display: block;
                    padding-inline: 16px;
                    margin-block-end: 0;
                }
            
                .Menu-ItemCaption {
                    padding-block-end: 0;
                }
            }
    
            @include mobile {
                .Menu-ItemCaption {
                    padding-block-start: 0;
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    columns: unset;
                }
            }
        }
    }

    &-MenuButtons {
        position: absolute;
        inset-block-end: 2px;
        width: 100%;
        height: 7vh;
        padding: 15px;
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #0d233f;
        background: #fff;

        @include mobile {
            height: 6vh;   
        }

        .Menu-SignInAccountButton, .Menu-LanguageButton {
            display: flex;
            align-items: flex-end;
            cursor: pointer;
            margin-inline-end: 40px;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-inline-end: 5px;
        }
    }
}

.SubMenu {
    &-Expandable {
        border: none !important;
    }

    &-ExpandableContentButton {
        padding: 0;

        svg {
            display: none;
        }
    }

    &-ExpandableContentHeading {
        padding: 0 !important;
        font-family: inherit;
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            padding-block-start: 5px;
            padding-inline-start: 15px;
        }
    } 
}

// ESTILOS CONFIGURABLES DESDE ADMIN
.menu-red figcaption {
    color: red !important;
}

.menu-red-500 figcaption {
    color: red !important;
    font-weight: 500;
}

.menu-bold figcaption {
    font-weight: bold !important;
}

.Menu-MenuWrapper.Original .menu-bold .Menu-ItemCaption {
    font-weight: bold !important;
}

.Menu-ItemList li.menu-spacer-xxs {
    padding-block-end: 10px;
}

.Menu-ItemList li.menu-spacer-xs {
    padding-block-end: 15px;
}

.Menu-ItemList li.menu-spacer-sm {
    padding-block-end: 50px;
}

.Menu-ItemList li.menu-spacer-md {
    padding-block-end: 80px;
}

.Menu-ItemList li.menu-spacer-lg {
    padding-block-end: 100px;
}

.Menu-ItemList li.menu-spacer-xlg {
    padding-block-end: 150px;
}

.Menu-ItemList .Menu-Item.solo-registrados {
    display: none;
}

.isApp .solo-app, .isApp .Menu-ItemList .Menu-Item.solo-app {
    display: block;
}

.notApp .solo-app, .notApp .Menu-ItemList .Menu-Item.solo-app {
    display: none;
}

.registrado .solo-registrados {
    display: block !important;
}

.notApp .registrado .solo-registrados.solo-app, .isApp .Menu-MenuWrapper.Original:not(.registrado) .solo-registrados.solo-app {
    display: none !important;
}

@supports (-webkit-touch-callout: none) {
    @include mobile {
        .Menu-MenuButtons {
            margin-block-end: 5px;
            inset-block-end: 25px;
            background: #fff;
            z-index: 10;
        }
    }
}
