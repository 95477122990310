/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBModAsesoramientoPage {
    &-ybIframe {
        display: block;
        min-height: 700px;
        height: 100%;
        width: 80%;
        margin: 50px auto;
    }

    &-icon {
        display: none;
        
        svg {
            font-size: 42px;
            width: 82px;
            height: 82px;
            background: rgb(255, 255, 255);
            border-radius: 50%;
            border: 1px solid rgb(3, 24, 65);
            padding: 15px;
            position: fixed;
            inset-block-start: 100px;
            z-index: 100;
            inset-inline-end: 100px;
            cursor: pointer;
        }
    }
}
