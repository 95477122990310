/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBGeoipPopup {
    .Popup-Content {
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding-block-start: 0;
        border-radius: 0;

        &::after {
            padding-block-end: 0;
        }

        .content {
            width: 100%;

            @include desktop {
                width: 800px;
            }

            display: inline-flex;

            .leftColumn {
                width: 100%;

                @include desktop {
                    width: calc(100% - 370px);
                    padding-inline-start: 50px;
                    padding-inline-end: 50px;
                }

                padding-inline-start: 30px;
                padding-inline-end: 30px;

                .titlePopup {
                    text-align: center;
                    color: #00295A;
                    padding-block-start: 20px;
                    font-size: 22px;
                    text-transform: uppercase;
                }

                .Select-Another-Text {
                    text-align: center;
                    color: #00295A;
                }

                .infoText {
                    color: #00295A;
                }

                .Buttons {
                    display: grid;

                    .YBGeoipPopup-Change-Store, .YBGeoipPopup-Stay-Store {
                        cursor: pointer;
                        height: 40px;
                        background-color: #00295A;
                        color: white;
                        margin-block-end: 10px;
                    }
                }

                .YBStoreSwitcher-SubmitButton {
                    width: 100%;
                    margin-block-start: 15px;
                }
            }

            .rightColumn {
                display: none;

                @include desktop {
                    display: block;
                    width: 370px;
                }

                .image_Popup {
                    @include desktop {
                        height: fit-content;
                        width: 370px;
                        float: inline-end;
                    }
                }
            }
        }

        .Popup-Header {
            .Popup-CloseBtn {
                inset-inline-end: 20px;
            }
        }
    }
}
