/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.TarjetaMonedero {
    &-Button {
        @include button;

        @include mobile {
            width: 100%;
            margin-inline-start: 0;
            margin-block: 14px 12px;
        }

        @include tablet-portrait {
            margin-inline-start: 0;
        }
    }
}

@media (min-width: 768px) {
    .TarjetaMonedero {
        &-Button {
            width: 100%;
        }

        &-Input {
            width: 100%;
            margin-block-end: 20px;
            
            input {
                width: 100%;
            }
        }

        .Form {
            display: block;
            width: 100%;
        }
    }
}
