/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.HomePage {
    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
            margin-block-start: 0;
            padding-inline-start: 0px;
            padding-inline-end: 0px;
        }

        &-Content {
            .pagebuilder-slider {
                display: none !important;
            }
        }
    }

    footer.FooterEmpy {
        display: none;
    }
    
    .Slider-Arrow_isNext, .Slider-Arrow_isPrev {
        display: none;
    }
}
