/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

:root {
    --header-frasehead-height: 0px;
}

.Notifications {
    font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;

    @include mobile {
        font-family: "futura-pt-light", sans-serif;
    }

    &-Notification {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #344079;
        position: relative;
        height: 100px;
        font-weight: 400;
        margin-block-end: 10px;
    }

    &-Image {
        width: 25%;
        text-align: center;
    }

    &-Picture {
        width: auto;
        height: 80px;
        margin: 0 auto;

        @include mobile {
            width: 100%;
            height: auto;
        }
    }

    &-Data {
        width: 75%;
        padding: 0 15px;
        font-size: 14px; 
        position: initial;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            padding-inline-end: 0;
        }

        p, span {
            color: #344079;
            margin-block-end: 0;
        }

        .timestamp {
            font-size: 11px;
            text-align: end;
        }
    }

    &-RightContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-inline-end: 15px;

        @include mobile {
            padding-inline-end: 5px;
        }
    }

    &-Delete {
        margin-block-end: 25px;
        cursor: pointer;
        z-index: 5;
    }

    &-AnyNotifications {
        text-align: center;
        margin-block-start: 50px;
        color: #344079;
        font-size: 20px;    
    }

    .Loader {
        margin: 60px 0;
    }
}

.NotificationList {
    inset-block-start: calc(var(--header-total-height) + 20px);
}
