/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.ItemListTiendaDisponibilidad {
    display: flex;
    flex-direction: row;
    padding: 20px 0 10px;

    & .closed {
        display: none;
    }

    & .opened {
        display: block;
    }

    & .iconShowInfo {
        cursor: pointer;
        margin-inline-end: 20px;
    }

    & .addressLine {
        display: flex;
    }

    & .toMapButton {
        cursor: pointer;
        font-weight: bold;
    }

    & .addressIcon, .phoneIcon, .locationIcon {
        width: 17px;
        height: 17px;
        margin-inline-end: 10px;
    }
    
    & .addressIcon {
        margin-block-start: 10px;
    }
}
