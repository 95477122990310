/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.NewsletterPage {
    .NewsletterSubscription-title {
        margin: 20px 0;
    }

    .NewsletterSubscription-body {
        margin-block-start: 30px;
    }

    @include desktop {
        padding-block-start: 20px;
        width: 50%;
        margin: 0 auto;
    }
}
