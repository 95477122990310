/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBFormWebForm {
    & .submit_error {
        color: red;
    }

    & .submit_success {
        color: green;
    }

    & input {
        width: 100%;
    }

    & .webform-checkbox {
        display: inline-flex;
        align-items: baseline;
        width: 100%;
    }

    & .webform-submit {
        display: block;
        font: 300 17px/22px 'Lato', sans-serif;
        line-height: 1.2;
        color: #fff;
        background-color: #00295A;
        padding: 12px 25px;
        border: solid 1px #00295A;
        transition: ease .3s;
        width: 50%;
        text-transform: uppercase;
        margin: 20px auto;
    }

    & .active {
        display: block;
    }

    & .no-active {
        display: none;
    }

    .oculto {
        display: none;
    }
    
    .inline-elements {
        display: inline-flex;
    }
}
