/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.Overlay {
    &:not(.Overlay_isStatic) {
        @include mobile {
            padding-block-end: 0 !important;
            margin-block-end: var(--navigation-tabs-height);
            box-shadow: 0 3px 3px rgba(0,0,0,0.15);
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .Overlay {
            margin-block-start: 45px;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}
