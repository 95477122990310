/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBChatbot {
    position: fixed;
    inset-block-end: 60px;
    inset-inline-end: 60px;
    z-index: 10000;
    min-height: 0 !important;

    &-CloseButton {
        position: absolute;
        z-index: 10;
        inset-block-start: 10px;
        inset-inline-end: 10px;
        cursor: pointer;

        .svg-inline--fa path {
            fill: white;
        }
    }

    &-Button {
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: -45px;
        cursor: pointer;
        border: 1px solid #00295a;
        border-radius: 100px;
        padding: 5px;
        background-color: white;

        &:hover {
            box-shadow: 1px 1px 10px rgba(0,0,0,.9);
            transform: scale(1.1);
        }

        &.chatbotApp {
            inset-block-end: 35px;
        }
    }

    &-ImageButton {
        width: 35px;
        height: 35px;
    }

    &-Chat {
        border: 1px solid #00295a;

        .react-chatbot-kit-chat-header {
            background-color: #00295a;
            border-bottom: 1px solid #00295a;
            border-radius: initial;
            color: white;
            font-size: 12px;
        }

        .react-chatbot-kit-chat-input-container {
            z-index: 1;
        }

        .react-chatbot-kit-chat-message-container {
            padding: 0 10px 10px;

            /* ===== Scrollbar CSS ===== */
            /* Firefox */
            scrollbar-width: thin;
            scrollbar-color: #0f233f #ffffff;

            /* Chrome, Edge, and Safari */
            &::-webkit-scrollbar {
                width: 10px;
                z-index: 1000;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #0f233f;
                border-radius: 10px;
                border: 0px solid #ffffff;
            }
        }

        .react-chatbot-kit-user-chat-message, .react-chatbot-kit-chat-input {
            font-size: 12px;
        }

        .react-chatbot-kit-chat-bot-message {
            font-size: 12px;
            margin-inline-start: 0;

            a {
                font-size: 12px;
                color: white;
                font-weight: bold;
                text-decoration: underline;
                cursor: pointer;
            }

            ul {
                &.urls-politicas {
                    margin-block-start: 6px;

                    li {
                        &::before {
                            display: none;
                        }

                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }
        }

        .react-chatbot-kit-user-chat-message {
            color: #00295a;
        }

        .react-chatbot-kit-chat-input-form {
            border-top: 1px solid #00295a;
        
            .react-chatbot-kit-chat-input {
                border-radius: initial;

                &:focus {
                    border: none;
                }
            }

            .react-chatbot-kit-chat-btn-send {
                border-radius: initial;
                cursor: pointer;
            }
        }

        .options {
            .swiper-slide {
                width: max-content;
                height: 30px;
                margin: 0 5px;
                border: 1px solid #00295a;
                border-radius: 35px;
                
                &:hover {
                    background-color: #00295a;
                    
                    a {
                        color: white;
                    }
                }

                a {
                    font-size: 12px;
                    line-height: 30px;
                    padding: 7px;
                    color: #00295a;
                }

                button {
                    font-size: 12px;
                    font-weight: bold;
                    height: 30px;
                    padding: 7px 10px;
                    color: #00295a;
                    cursor: pointer;
                    
                    &:hover {
                        color: white;
                    }
                }
            }
        }

        .request-order-form {
            .FieldForm-Fields {
                text-align: center;
            }

            .Field {
                margin-block-start: 10px;
            }

            .getOrderButton {
                margin-block-start: 10px;
                height: 40px !important;
            }
        }

        .order-info-container {
            width: 92%;
            margin: 0px 4%;
            border: 1px solid;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            & .order-info-item {
                width: 100%;
                padding: 5px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                & .label {
                    width: 40%;
                    font-weight: bold;
                }

                & .right {
                    width: 60%;
                    text-align: end;
                }

                &.full {
                    width: 100%;
                }
            }

            .order-see-more {
                a {
                    font-size: 12px;
                    text-decoration: underline;
                    color: black;
                }
            }

            .order-track {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                border-top: 1px solid;

                .track-title {
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    font-size: 13px;
                    margin: 6px 0;
                }

                .track-item {
                    width: 100%;
                    margin-block-end: 5px;
                    
                    .ShipmentInfo {
                        text-align: center;
                        margin: 5px 0;

                        a {
                            text-transform: capitalize;
                            font-size: 13px;

                            &.track-url {
                                width: 90%;
                                margin: 5px 5%;
                                text-align: center;
                                text-decoration: underline;
                            }
                        }
                        
                        div {
                            &.track-url {
                                color: #0d233f;
                                text-transform: capitalize;
                                font-size: 13px;
                                width: 90%;
                                margin: 5px 5%;
                                text-align: center;
                                text-decoration: underline;
                            }
                        }
                    }

                    .ShipmentItems {
                        width: 95%;
                        margin: 5px 2.5%;

                        .itemTitle {
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    .ShipmentHistory {
                        width: 95%;
                        margin: 5px 2.5%;
                        
                        .itemTitle {
                            font-weight: bold;
                            text-transform: uppercase;
                        }

                        .historyItem {
                            padding-block-end: 5px;
                        }
                    }
                }   
            }
        }


        .rma-info-container {
            width: 92%;
            margin: 0px 4%;
            border: 1px solid;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            & .rma-info-item {
                width: 100%;
                padding: 5px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                & div {
                    width: 60%;
                }

                & .label {
                    width: 40%;
                    font-weight: bold;
                }

                & .right {
                    text-align: end;
                }

                &.full {
                    width: 100%;
                }
            }

            .rma-see-more {
                a {
                    font-size: 12px;
                    text-decoration: underline;
                    color: black;
                }
            }
        }

        .social-media-container {
            .swiper-slide {
                width: max-content;

                .Image {
                    width: 30px;
                    margin: 0 5px;
                }
            }
        }

        .download-apps-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            a {
                width: 70%;
                margin: 0 1%;
            }
        }

        .request-disponibilidad-form {
            .FieldForm-Fields {
                text-align: center;
            }

            .Field {
                margin-block-start: 10px;
            }

            .getDisponibilidadButton {
                margin-block-start: 10px;
                height: 40px !important;
            }
        }

        .search-form {
            .chatbot-search-container {
                input {
                    width: 100%;
                    height: 35px;
                    padding: 15px;
                }

                #chatbot-search-close {
                    position: absolute;
                    inset-inline-end: 5px;
                    inset-block-start: calc(50% - 12px);
                    height: 24px;
                }
            }

            .chatbot-search-results {
                max-height: 140px;
                overflow-y: scroll;
                overflow-x: hidden;
                margin: 5px 0;

                .chatbot-search-results-item {
                    display: flex;
                    align-items: center;
                    padding: 5px;

                    &::before {
                        display: none;
                    }

                    &:hover {
                        color: #00295a;
                    }

                    .Image {
                        width: max-content;

                        &_isPlaceholder {
                            min-height: 60px;
                            min-width: 60px;
                        }

                        img {                       
                            width: auto;
                            height: 60px;
                        }
                    }

                    .chatbot-search-results-item-info {
                        padding-inline-start: 10px;

                        .price {
                            &:hover {
                                color: #00295a;
                            }
                        }
                    }
                }
            }

            .chatbot-search-noresults {
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 13px;
                margin: 14px 0;
                color: red;
            }

            .chatbot-search-button {
                width: max-content;
                margin: 0 auto;

                .Button {
                    font-size: 12px;
                    height: 35px;
                }
            }
        }
    }

    .chatbotOpen {
        display: block;
    }

    .chatbotClose {
        display: none;
    }
}
