/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.SearchTabPage {
    padding-block-start: 10px;
}

.botones-bajo-buscador {
    text-align: center;
    padding: 20px 0px;
}

.storesIconButton, .scanIconButton, .microphoneIconButton {
    width: 33.333%;
}

.SpeechRecognition {
    width: 33.333%;
    position: relative;
    float: inline-end;
}

.storesIconText, .scanIconText, .microphoneIconText {
    margin-block-start: 3px;
}
