/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBNewRma {
    & .Form {
        & .FieldForm-Body {
            padding-inline-start: 5px;
            padding-inline-end: 5px;

            & .Field {
                display: inline-flex;
                width: 100%;

                & .Field-LabelContainer {
                    width: 30%;
                    color: #333;
                    font-size: 14px;
                    align-items: flex-end;

                    @include desktop {
                        width: 20%;
                    }
                }

                & input {
                    width: 70%;
                    height: 30px;
                    line-height: 35px;
                    padding-inline-start: 15px;
                    padding-inline-end: 30px;
                    padding-block-start: 5px;
                    padding-block-end: 0px;

                    @include desktop {
                        width: 90%;
                    }
                }

                & .FieldSelect {
                    width: 70%;
                    height: 30px;
                    line-height: 30px;
                    padding-inline-start: 0px;
                    padding-inline-end: 30;
                    padding-block-start: 5px;
                    padding-block-end: 0px;

                    @include desktop {
                        width: 90%;
                    }

                    & .FieldSelect-Select {
                        height: 30px;
                        line-height: 30px;
                        padding-block-start: 0px;
                        padding-block-end: 0px;
                        padding-inline-start: 15px;
                    }
                }
            }

            & .YBNewRmaDirRecogidaForm-rma-Button {
                width: 100%;
                margin-block-start: 20px;
                margin-block-end: 50px;
                height: 50px;
                background-color: #00295a;
                color: #fff;
                font-size: 17px;
                font-weight: 300;
                text-transform: uppercase;
            }
        }

        & .block-msg {
            width: 100%;
            height: 30px;

            & .errorCreateRma {
                line-height: 35px;
                color: red;
                font-size: 14px;
            }

            & .successCreateRma {
                line-height: 35px;
                color: green;
                font-size: 14px;
            }
        }
    }
}
