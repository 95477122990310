/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';
 

.payment-request-button {
    --loader-scale: .5;
    
    background-color: var(--color-gray);
    height: 50px;
    border-radius: 4px;
}

.CartPage .CheckoutOrderSummary-ButtonWrapper {
    @include mobile {
        height: auto;
        // ^^^ needed to make actions area adjustable
    }

    .payment-request-button {
        margin-block-end: 14px;

        @include mobile {
            margin-block-start: 14px;
            margin-block-end: 0;
        }
    }
}

.CartOverlay {
    &-Actions {
        flex-wrap: wrap;
    }

    .payment-request-button {
        flex-basis: 100%;
        margin-block-start: 12px;
    }
}

.ProductActions {
    .payment-request-button {
        margin-block-start: 24px;
        margin-inline-end: 16px;
        // ^^^ Needed to match add to cart ?
    }
}

.Checkout_isConfirmationFailed {
    .CheckoutBilling > * {
        &:not(.Checkout-StickyButtonWrapper) {
            &:not(.CheckoutPayments) {
                display: none;
            }
        }
    }

    .CheckoutPayments-Methods,
    .StripePayments-Select,
    .StripeCheckoutExpress {
        display: none;
    }
}

.StripeCheckoutExpress {
    &-Title {
        &.Checkout-Heading {
            margin-block-end: 10px;
        }
    }
}