/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

@-moz-keyframes muestradisponibilidad {
    from {
        transform: translateX(400px);
    }

    to {
        transform: translateX(0px);
    }
}

@-webkit-keyframes muestradisponibilidad {
    from {
        transform: translateX(400px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes muestradisponibilidad {
    from {
        transform: translateX(400px);
    }

    to {
        transform: translateX(0px);
    }
}

.DisponibilidadEnTienda {
    &-Wrapper {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        
        .DisponibilidadEnTienda-Popup .ExpandableContent-Heading {
            padding-inline-start: 0px;

            @include desktop {
                padding-inline-end: 0px;
            }
        }
    }

    &-Popup {
        svg {
            vertical-align: unset;
            inset-block-start: 13px;
            margin-inline-end: 10px;
        }

        @include desktop {
            .Popup-Heading {
                display: none;
            }
            
            .Popup-Content {
                width: 60%;
            }
        }
    }

    &-ContentWindow {
        background-color: white;
        z-index: 10;
        padding: 0% 4%;

        @include desktop {
            position: fixed;
            inset-block-start: 0;
            inset-inline-end: 0;
            width: 50%;
            height: 100vh;
            overflow-y: scroll;
            padding-block-end: 4%;
            // Animacion abrir menu
            animation-name: muestradisponibilidad;
            animation-duration: 400ms;
            animation-timing-function: linear;
            -webkit-animation-name: muestradisponibilidad;
            -webkit-animation-duration: 400ms;
            -webkit-animation-timing-function: linear;
        }

        @include mobile {
            width: 100%;
        }

        &.hideDisponibilidad {
            display: none;
        }

        & .closeDisponibilidad {
            padding: 0 10px;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 4%;
            z-index: 10;
            cursor: pointer;

            @include mobile {
                display: none;
            }
        }
    }

    &-PopupContainer {
        margin-block-start: 30px;

        @include mobile {
            margin-block-start: 15px;
        }
    }

    &-PopupHeading {
        font: 14px/22px 'Lato',sans-serif;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        color: #00295a;
        margin: 10px 20px 20px;

        @include desktop {
            font: 18px/22px 'Lato',sans-serif;    
            font-weight: 300;
        }

        @include mobile {
            display: none;
        }
    }

    &-PopupDescription {
        font: 14px/17px 'Lato',sans-serif;
        font-weight: 300;
        color: #6a6a6a;
    }

    &-PopupForm {
        & .Field_type_text.Field_isValid input, & .Field_type_select.Field_isValid select {
            border: 1px solid #e0e0e0;
        }
    }

    &-PopupFooter {
        margin: 20px 0;
        margin-block-end: 50px;

        .localizaciones {
            li {
                &::before {
                    display: none;
                }

                &.tienda {
                    border-bottom: 1px solid black;
                }
            }           

            p.aviso {
                color: red;
                text-align: center;
                font-weight: bold;
            }
        }
    }

    &-CartTabs {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        margin-block-end: 15px;
        
        @include desktop {
            margin-block-end: 15px;
        }

        .tab {
            font: 14px/20px 'Lato',sans-serif;
            color: #00295a;
            text-transform: uppercase;
            cursor: pointer;
            width: 50%;
            text-align: center;
            padding: 5px 0;
            border: 1px solid #00295a;

            svg {
                margin-inline-start: 3px;
                width: 13px;
                height: 15px;
            }
        }

        .activeTab {
            font-weight: bold;
            background-color: #00295a;
            color: white;
        }
    }

    &-CartTabsContent {
        @include desktop {
            min-height: 45vh;
        }
    }

    &-CartTabItem {
        &.closed {
            display: none;
        }
    
        &.opened {
            display: block;
        }
    }

    &-SubmitButton {
        @include desktop {
            width: 100%;
        }
    }
}
