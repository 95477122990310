/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBEncuestas {
    width: 100%;
    min-height: 100px;
    margin: 0 auto;
    position: fixed;
    inset-block-end: 0;
    background-color: #FFF;
    z-index: 100000000;
    box-shadow: 0px -2px 20px 0px rgba(215, 215, 215, 0.67);

    .closeEncuesta {
        float: inline-end;
        padding: 10px 30px;
        cursor: pointer;

        @include mobile {
            padding: 5px 20px 0px 0px;
        }
    }

    .YBFormWebForm {
        max-width: 900px;
        margin: 20px auto;
    }

    .FieldGroup-Wrapper {
        text-align: center;
        font-weight: 300 !important;
        font-size: 24px;
        color: #0f233f;
        margin-block-end: 15px !important;
        letter-spacing: 1px;

        & .inline-elements {
            @include mobile {
                display: block;
            }

            & .field.choice {
                @include mobile {
                    display: inline-flex;
                }
            }
        }
    }

    .FieldGroup-Wrapper .FieldGroup_isValid {
        padding-inline-start: unset;
        border-left: unset;
    }
    
    .FieldGroup-Wrapper legend {
        font-size: 24px;
    }

    .FieldGroup-Wrapper .FieldGroup-Wrapper-Legend {
        margin: 30px auto 20px;

        @include mobile {
            margin: 30px auto 5px;
            font-size: 20px;
            padding-block-start: 10px;
        }
    }

    .field.choice {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 15px;
        line-height: 50px;
        padding: 0;

        .Field-Wrapper_type_radio {
            font-weight: 300;
            font-size: 20px;
            color: #0f233f;
            border: 1px solid #0f233f;
            border-radius: 6px;
            width: 50px;
            height: 50px;
            cursor: pointer;
        }

        .Field_type_radio {
            margin-block-start: 0px !important;
        }

        .Field-RadioLabel {
            width: 100%;

            @include mobile {
                vertical-align: unset;
            }
        }

        .Field-RadioLabel_isChecked {
            font-weight: 500 !important;
            border-radius: 6px;
            height: 50px;
            width: 52px;
            inset-block-start: -4px;
            background-color: #0f233f;
            color: #fff;
        }

        @include mobile {
            .Field-RadioLabel_isChecked {
                font-weight: 500 !important;
                border-radius: 6px;
                height: 50px;
                width: 52px;
                inset-block-start: -1px;
                background-color: #0f233f;
                color: #fff;
            }
        }

        .Field-RadioLabel .input-control {
            opacity: 0 !important;
            position: absolute;
            width: 100%;
        }

        .FieldRadio-label {
            position: relative;
            width: 100%;
            inset-block-start: 1px;
            text-align: center;
        }

        .FieldGroup.radio-container {
            text-align: start;
        }
    }

    .Field-Wrapper_type_textarea {
        text-align: center;
    }

    .Field-Wrapper_type_textarea .Field-LabelContainer {
        display: unset;
    }

    .Field-Wrapper_type_textarea .Field-LabelContainer .Field-Label {
        font-weight: 300 !important;
        font-size: 24px;
        color: #0f233f;
        margin-block-end: 15px !important;
        letter-spacing: 1px;

        @include mobile {
            margin: 30px auto 5px;
            font-size: 20px;
            padding-block-start: 10px;
        }
    }

    .Field-Wrapper_type_textarea textarea {
        width: 100%;

        @include mobile {
            width: 90%;
            margin: 0 auto;
        }
    }

    .wrapper-buttons {
        text-align: center;

        button {
            font: 300 17px/22px 'Lato', sans-serif;
            line-height: 1.2;
            border: 1px solid #0f233f;
            color: #fff;
            background-color: #0f233f;
            border-radius: 6px;
            padding: 12px 25px;
            transition: ease .3s;
            text-transform: uppercase;
            cursor: pointer;

            &.webform-submit {
                color: #fff !important;
                width: unset;
                margin: 15px;
                display: unset;
            }
        }
    }

    .Field-Wrapper_type_checkbox {
        @include mobile {
            width: 100%;
        }

        .Field_type_checkbox {
            @include mobile {
                width: 90%;
                margin: 5px auto;
            }
        }
    }

    .YBFormWebForm .submit_success {
        text-align: center;
        font-size: 20px;
        color: green;
        padding: 20px;
    }
}
