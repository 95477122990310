/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.CmsPage {
    &-Content {
        .col-md-10 {
            width: 88%;
            margin: 0 auto;
        }

        h1 {
            font-size: 20px;
            letter-spacing: 9px;
            text-align: center;
            color: #00295a;

            @include mobile {
                font-family: "futura-pt-book", sans-serif !important;
                font-size: 18px !important;
                margin: 0 0 20px;
                padding: 10px 0 0;
                color: #363b4f;
                font-weight: 600 !important;
                letter-spacing: 1px !important;
                text-align: start;
            }
        }

        p, li {
            color: #272f66;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.42857143;

            @include desktop {
                font-size: 15px;
            }
        }

        span {
            font-size: 12px;
            font-weight: 400;
        }

        b {
            color: #272f66;
            font-size: 15px;
        }

        p, h1, li {
            font-family: 'Lato',sans-serif;
        }

        h2 {
            font: normal normal 300 30px/24px 'Lato',sans-serif !important;
            color: #00295a;
        }

        ul {
            padding-inline-start: 15px;
            line-height: 26px;

            &.ProductListWidget-Page {
                padding: 0;
            }
        }

        table {
            width: 100% !important;
        }

        .GPDR-table {
            width: 75% !important;
            border: 1px solid #223041;
            margin: 0 auto;

            td {
                font-family: 'Lato',sans-serif;
                color: #223041;
                font-weight: 300;
                font-size: 15px;
            }
        }

        .tablezone {
            td {
                background-color: #ffffff;
                color: #223041;
                text-align: start;
                font-size: 15px;
            }
            
            tr {
                border: none !important;
            }
        }
    }

    &-Wrapper {
        margin-block-start: calc(var(--header-total-height) / 2);
        max-width: 100%;
    }

    ul, ol {
        li {
            &::before {
                display: none;
            }
        }
    }
}
