/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBNewRma {
    padding-inline-start: 10px;
    padding-inline-end: 10px;

    @include desktop {
        max-width: 800px;
        margin: 0 auto;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
    }

    & .select-pedido {
        & .Field-Label {
            font-size: 14px;
            color: #344079;
            font-weight: 700;
        }

        & .Field-Label_isRequired {
            color: red;
        }

        & .FieldSelect-Clickable {
            width: 80%;

            @include desktop {
                width: 100%;
            }

            & .FieldSelect-Select {
                height: 40px;
                line-height: 40px;
                padding-inline-start: 15px;
                padding-inline-end: 40;
                padding-block-start: 0;
                padding-block-end: 0;
            }
        }
    }

    & .select-resolucion {
        margin-block-start: 15px;

        & .Field-Label {
            font-size: 14px;
            color: #344079;
            font-weight: 700;
        }

        & .Field-Label_isRequired {
            color: red;
        }

        & .FieldSelect-Clickable {
            width: 80%;

            @include desktop {
                width: 100%;
            }

            & .FieldSelect-Select {
                height: 40px;
                line-height: 40px;
                padding-inline-start: 15px;
                padding-inline-end: 40;
                padding-block-start: 0;
                padding-block-end: 0;
            }
        }
    }

    & .msg-alert {
        margin-block-start: 15px;
        padding-inline-start: 18px;
        color: red;
        text-align: center;
    }

    & .tabla-articulos, & .info-adicional, & .dir-recogida {
        margin-block-start: 20px;
        width: 100%;

        & .items-title {
            font-size: 14px;
            color: #344079;
            font-weight: 700;
            margin-block-end: 5px;

            & .items-title-require {
                color: red;
            }
        }
    }

    & .info-adicional {
        & textarea {
            height: 100px;

            @include desktop {
                width: 100%;
            }
        }
    }

    .payment_method img {
        height: 40px;
        width: auto;
    }

    .payment_method input {
        width: 10px;
        height: 10px;
        min-width: 10px;
        min-height: 10px;
    }

    .payment_method .items-title {
        font-size: 14px;
        color: #344079;
        font-weight: 700;
        margin-block-end: 5px;
        margin-block-start: 15px;
    }
}
