/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.BlogPosts {
    font-family: 'Lato', sans-serif !important;
    padding: 0 14px;
    color: #223041;

    @include desktop {
        padding-block-start: 20px;
    }

    .container {
        width: 100%;
        padding: 0;
    }

    .blk-text-news {
        h2 {
            @include mobile {
                font: normal normal 300 30px/24px 'Lato',sans-serif !important;
                letter-spacing: 9px !important;
                text-align: center !important;
                color: #00295a !important;
                padding-block-end: 5px;
                border-bottom: 1px solid #344079;
            }

            @include desktop {
                font: normal normal 300 30px/24px 'Lato',sans-serif !important;
                letter-spacing: 9px !important;
                text-align: center !important;
                text-transform: uppercase;
            }
        }
    }

    .postWrapper {
        a {
            color: #344079;
        }

        img {
            width: 75%;
            
            @include desktop {
                max-width: 250px;
            }
        }

        .tabla-horarios {
            width: 100%;
        }
    }

    @include desktop {
        .postContent {
            margin: 0 10%;
        }
    }
}

.row {
    display: table;
    content: " ";

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        position: relative;
        min-height: 1px;
        padding-inline-end: 15px;
        padding-inline-start: 15px;
    }

    /* SM Sizes */
    @media (max-width: 767px) { 
        .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
            float: inline-start;
        }

        .col-xs-0 {
            width: 0%;
        }

        .col-xs-1 {
            width: 8.33336667%;
        }

        .col-xs-2 {
            width: 16.66666667%;
        }

        .col-xs-3 {
            width: 25%;
        }

        .col-xs-4 {
            width: 33.33333333%;
        }

        .col-xs-5 {
            width: 41.66666667%;
        }

        .col-xs-6 {
            width: 50%;
        }

        .col-xs-7 {
            width: 58.33333333%;
        }
    }

    /* MD Sizes */
    @media (min-width: 768px) { 
        width: 100%;
        
        .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
            float: inline-start;
        }

        .col-md-0 {
            width: 0%;
        }

        .col-md-1 {
            width: 8.33336667%;
        }

        .col-md-2 {
            width: 16.66666667%;
        }

        .col-md-3 {
            width: 25%;
        }

        .col-md-4 {
            width: 33.33333333%;
        }

        .col-md-5 {
            width: 41.66666667%;
        }

        .col-md-6 {
            width: 50%;
        }

        .col-md-7 {
            width: 58.33333333%;
        }
    }
}

.Header-Title_isVisible {
    display: none !important;
}
