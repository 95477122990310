/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.MyGiftCardsPage {
    @include desktop {
        max-width: 900px;
        margin: 30px auto;
        width: 100%;
    }

    @include mobile {
        padding: 0 14px;
    }

    &-HeaderTitle {
        @include mobile {
            font-family: "futura-pt-book", sans-serif;
            font-size: 18px;
            margin: 0 0 20px;
            padding: 10px 0 0;
            color: #363b4f;
            font-weight: 600;
            letter-spacing: 1px;
        }

        @include desktop {
            font: normal normal 300 30px / 24px 'Lato', sans-serif;
            letter-spacing: 5px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &-TextoTarjeta {
        font-size: 14px;
        text-align: center;

        strong {
            margin-block-end: 5px;
        }

        span {
            color: #363b4f !important;
        }
    }

    &-RegalaTarjeta {
        @include desktop {
            display: none;
        }

        margin-block-start: 30px;
    }

    .no-cards {
        color: red;
        text-align: center;
        padding-block-start: 15px;
        font-size: 16px;
    }
}
