/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

:root {
    --header-logo-width: 140px;
    --header-logo-height: 27px;
    --header-nav-height: 40px;
    --header-total-height: 40px;
}

@-moz-keyframes muestramenu {
    from {
        transform: translateX(-400px);
    }

    to {
        transform: translateX(0px);
    }
}

@-webkit-keyframes muestramenu {
    from {
        transform: translateX(-400px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes muestramenu {
    from {
        transform: translateX(-400px);
    }

    to {
        transform: translateX(0px);
    }
}

.Header {
    .Logo > img {
        @include desktop {
            width: 150px;
            height: 40px;
        }
    }

    &-ButtonSearch, #myAccount, &-MinicartButtonWrapper {
        width: 20px;
        height: auto;
    }

    &-LogoWrapper {
        width: 100%;

        @include mobile {
            width: calc(100% - 60px);
        }

        .Image {
            width: var(--header-logo-width);
            display: block;
            margin: 0 auto;

            @include mobile {
                width: max-content;
            }
        }
    }

    @include desktop {
        border: none;
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: stretch;

        &-Nav {
            padding: 0 20px;
        }

        &-MenuGansoWrapper {
            grid-column: 1 / 3;

            .fa-bars {
                cursor: pointer;
            }
        }

        > * {
            max-width: 100%;
            width: 100%;
        }

        &-MyAccountContainer {
            display: flex;
            margin-inline-start: 0px;
        }

        &-MyAccountContainer, &-SearchContainer, &-ShareFooterContainer, &-Button_type_minicart {
            padding-inline-start: 10px;
        }

        &-IconsWrapper {
            grid-column: 3 / 3;
            display: flex;
            justify-content: flex-end;
        }

        &-LogoWrapper {
            grid-column: 2 / 3;
            margin: 0 auto;
        }

        &-MenuIzquierdaHeader {
            position: fixed;
            inset-inline-start: 0;
            inset-block-start: 0;
            overflow-y: hidden;
            height: 100%;
            width: 400px;
            z-index: 99999;
            background: #fff;

            & .Menu-MenuWrapper {
                padding: 0px;
                overflow: hidden;
                height: calc(93vh - 60px);
                overflow-y: scroll;
            }

            & .closeMenu {
                padding: 20px 10px;
                position: absolute;
                inset-inline-end: 0;
                z-index: 999999;
                cursor: pointer;
            }

            animation-name: muestramenu;
            animation-duration: 200ms;
            animation-timing-function: linear;
            -webkit-animation-name: muestramenu;
            -webkit-animation-duration: 200ms;
            -webkit-animation-timing-function: linear;
        }

        &-MinicartItemCount {
            inset-inline-end: -12px !important;
            inset-block-start: 11px;
            height: 16px;
            min-width: 16px;
            font-size: 10px;
        }

        &-Wrapper:has(.YBFraseHead) {
            margin-block-end: calc(var(--header-total-height) + 20px);
        }
    }

    @include mobile {
        &-IconsWrapper {
            display: flex;
            justify-content: flex-end;
            gap: 5px;
        }

        &-MenuIzquierdaHeader {
            &.notApp, &.isApp {
                position: absolute;
                inset-block-start: 40px;
                inset-inline-start: 0;
                width: 100vw;
                height: auto;
                min-height: 300px;
                max-height: 95vh;
                z-index: 99999;
                background: #fff;

                & .Menu-MenuWrapper {
                    padding: 10px 20px;
                    height: calc(100vh - 82px);
                    overflow-y: scroll;
                    min-height: 300px;
                }

                & .Menu-MenuButtons {
                    inset-block-end: 0;
                }

                & .closeMenu {
                    display: none;
                }
            }

            &.isApp {
                height: calc(100vh - 82px);
            }

            &.notApp {
                & .Menu-MenuButtons {
                    inset-block-end: 65px;
                }
            }
        }

        & .fa-sharp {
            inset-block-start: 2px;
        }
    }

    &-ShareFooter {
        @supports (-webkit-touch-callout: none) {
            .ShareFooter-menu {
                inset-block-start: 120px;
            }
        }

        .ShareFooter-menu {
            background-color: white;
            position: fixed;
            width: 90%;
            max-width: 500px;
            inset-block-start: 60px;
            inset-inline-start: 50%;
            transform: translate(-50%, 0);
            box-shadow: 0 0 20px 10px rgba(0,0,0,0.2);
            border-radius: 10px;
            border: 1px solid #d7d7d7;
            padding: 10px 40px;

            .ShareFooter-list {
                color: #4b4b4b;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                line-height: 52px;

                .ShareFooter-link {
                    color: unset;
                    font-size: unset;
                    font-weight: unset;

                    .separador {
                        border-bottom: 1px solid #d0d0d0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .Header {
        border: none;

        &.Header_name_popup {
            .Header-LogoWrapper_isVisible {
                pointer-events: none;
            }
        }

        &-MinicartItemCount {
            inset-inline-end: -2px !important;
            inset-block-start: 11px;
            height: 15px;
            min-width: 15px;
            font-size: 10px;
        }
        
        &-name_menu {
            padding: 0 5px;
        }

        &  .svg-inline--fa {
            width: 20px;
            height: 19px;
        }

        img {
            width: 20px;
            height: auto;
        }

        &-Wrapper {
            margin-block-end: 0px;
            height: auto;
        }

        &-Wrapper:has(.YBFraseHead) {
            & .Menu-MenuButtons {
                height: 0;
            }
        }

        &-LogoWrapper {
            & img {
                width: 140px;
                height: 27px;
                text-align: center;
            }
        } 

        &-Title_isVisible {
            opacity: 0;
        }

        &-Button_type_search {
            opacity: 1;
            height: 25px;
            width: 25px;
            pointer-events: all;
            overflow: visible;
            cursor: pointer;
            margin-inline-end: 6px;
        }

        &-MyAccountWrapper {
            opacity: 1;
            height: 25px;
            width: 25px;
            pointer-events: all;
            overflow: visible;
            cursor: pointer;
        }

        &-Button_type_back {
            display: block !important;
            width: 45px;
        }

        &-subHeader {
            margin-block-start: 45px;
            width: 100%;
            
            & .SearchField {
                padding: 0px 15px;

                &-Input {
                    border: none; 
                    border-bottom: 1px solid #00295a;
                    padding: 5px 10px;
                    transition: .3s;
                    height: 32px;
                }

                &-SearchIcon {
                    height: 14px;
                    inset-block-start: calc(50% - 5px);
                    inset-inline-end: 6px;
                }
            }

            & .fa-magnifying-glass {
                width: 14px;
                height: 14px;

                & path {
                    fill: #B4ACAA;
                }
            }
        }

        &-Button_type_share {
            display: none;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    @media (max-width: 767px) {
        .Header {            
            &-Wrapper.isApp {
                height: max-content;
            }

            &-MenuIzquierdaHeader {
                &.isApp {
                    height: calc(100vh - 175px);

                    & .Menu-MenuWrapper {
                        height: auto;
                        height: calc(100vh - 175px);
                    }
                }
            }
        }

        .NotificationList {
            inset-block-start: 85px;
        }
    }

    /* Ipad */
    @media (min-width: 768px) {
        // TODO style the element
    }
}

@media (min-width: 768px) {
    .Header_isCheckout {
        .Header-LogoWrapper {
            .Image {
                background-color: #f4f4f4 !important;
            }
        }

        .Header-MenuGansoWrapper {
            opacity: 0;
        }

        .Header-IconsWrapper {
            opacity: 0;
        }
    }

    .Header-Button_type_minicart {
        margin-inline-start: 0;
    }

    .Header_isCheckout .Header-Nav {
        display: grid;
        background-color: #f4f4f4 !important;
    }

    .Header-MenuGansoWrapper {
        grid-column: 1;
    }
}
