/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

@-moz-keyframes parpadeo {  
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes parpadeo {  
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes parpadeo {  
    0% {
        opacity: 1.0;
    }
    
    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

.SpeechRecognition {
    & .microphoneIconButton_type_escuchando .fa-microphone path {
        fill: red;
        animation-name: parpadeo;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-name: parpadeo;
        -webkit-animation-duration: 2s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
    }

    & .microphoneIconButton_type_escuchando .microphoneIconText {
        color: red;
        animation-name: parpadeo;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-name: parpadeo;
        -webkit-animation-duration: 2s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
    }
}
