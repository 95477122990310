/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

.MyPaymentMethods {
    padding-block-start: 20px;

    &-MethodLabel {
        display: flex;
        align-items: center;
        min-height: 60px;

        img {
            width: 40px;
            margin-inline-end: 15px;
        }

        span {
            flex-grow: 1;
        }
    }

    &-Table {
        width: 100%;

        td, th {
            vertical-align: middle;
        }
    }

    &-Title {
        // vvv Copied this from account dashboard styles
        border-bottom: var(--my-account-content-border);
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        padding-block-end: 6px;
        width: 100%;
        margin-block-end: 25px;
    }

    &-Element {
        min-height: 100px;
        margin-block-end: 15px;
    }

    &-NoPaymentMethods {
        text-align: center;
    }
}
