/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.YBAccessibility {
    // TODO style the element
}

.circular._access-icon {
    height: 35px !important;
    width: 35px !important;
    background-color: #0d233f !important;
    font-size: 25px !important;
    line-height: 30px !important;
}

._access-menu ._menu-btn {
    inset-block-start: 0 !important;
}

._access-menu ._text-center {
    font-size: 16px !important;
    zoom: 0.7;
    color: #344079 !important;
}

.screen-reader-wrapper-step-1, .screen-reader-wrapper-step-2, .screen-reader-wrapper-step-3 {
    display: none;
}

._access-scrollbar li::before {
    display: none;
}
