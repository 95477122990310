/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.MyPoints {
    font-family: 'Futura PT','Helvetica Neue',Helvetica,Arial,sans-serif;

    @include mobile {
        font-family: "futura-pt-light", sans-serif;
        color: #363b4f;

        p {
            color: #363b4f;
        }

        .disabledAccount {
            text-align: center;
            font-size: 18px;
        }

        .titPoints {
            font-size: 16px;
            font-family: "futura-pt-demi", sans-serif;
        }
        
        .subTitPoints {
            font-family: "futura-pt-demi", sans-serif;
            text-decoration: underline;
            margin-block-end: 5px;
        }
    }

    &-RewardBalance {
        .box-balance {
            font-size: 20px;
            padding: 0 20px;
            margin: 10px 0;
            text-align: center;

            @include mobile {
                padding-inline-start: 0;
                padding-inline-end: 0;
            }

            .item-balance {
                background: #00295a !important;
                height: 65px;
                line-height: 65px;
                color: #fff;
                font-size: 20px;
            }
    
            .label {
                font-size: 14px;
                padding-block-start: 5px;
                color: #223041;
            }
        }

        .balance-text {
            @include mobile {
                text-align: center;
                font-size: 16px;
                margin-block-end: 20px;

                .bold {
                    font-family: "futura-pt-demi", sans-serif;
                }
            }
        }
    }

    &-RewardInfo {
        font-size: 14px;
        margin-block-end: 30px;
        padding: 0 20px;
        padding-block-end: 30px;
        border-bottom: 1px dashed;

        @include mobile {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }

        .box-title {
            font-weight: bold;
        }
    }

    &-DonacionesEmail {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        margin-block-end: 30px;
        padding: 0 20px;
        padding-block-end: 30px;
        border-bottom: 1px dashed;
        text-align: center;

        @include mobile {
            padding-inline-start: 0;
            padding-inline-end: 0;
            font-family: "futura-pt-light", sans-serif;

            div, p, button {
                font-family: "futura-pt-light", sans-serif;
            }
        }

        .donaciones-title {
            line-height: 29px;
            font-weight: 300;
            color: #344079;
            letter-spacing: 0;
            text-transform: uppercase;
            margin: 20px auto;
            font-size: 18px;

            @include mobile {
                font-family: "futura-pt-book", sans-serif;
                font-size: 16px;
                margin: 0 0 20px;
                padding: 10px 0 0;
                color: #363b4f;
                font-weight: 600;
                letter-spacing: 1px;
                border: none;
                text-align: start;
                text-transform: none;
            }
        }

        .donaciones-descripcion {
            font-size: 14px;
            color: #223041;
            margin-block-end: 10px;

            @include mobile {
                font-family: "futura-pt-light", sans-serif;
                color: #363b4f;
            }
        }

        .form-donacion {
            width: 50%;
            max-width: 600px;
            margin: 0 auto;

            @include mobile {
                width: 90%;
            }
        }
    }

    .DonacionesEmail {
        &-Aux {
            font-size: 14px;
            color: #223041;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 15px 0;
        }

        &-AuxTop {
            input {
                width: 70px;
                margin-inline-end: 10px;
                background: #ffffff;
                background-clip: padding-box;
                border: 1px solid #c2c2c2;
                border-radius: 1px;
                font-size: 14px;
                height: 32px;
                padding: 0 9px;
                vertical-align: baseline;
                box-sizing: border-box;
            }
        }

        &-AuxBottom {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
        }

        &-MaximizarDonacion {
            margin: 0;
        }

        &-Email {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-block-end: 20px;
        }

        &-EmailInput {
            margin: 0;
            margin-inline-start: 5px;

            input {
                background: #ffffff;
                background-clip: padding-box;
                border: 1px solid #c2c2c2;
                border-radius: 1px;
                font-size: 14px;
                height: 32px;
                padding: 0 9px;
                vertical-align: baseline;
                box-sizing: border-box;
            }
        }

        &-Result {
            font-family: 'Lato', sans-serif;
            font-size: 12px;
            margin: 10px;

            .success {
                color: green;
            }

            .error {
                color: red;
            }
        }

        &-BtnDonacion {
            background: #081d4b;
            border: 1px solid #081d4b;
            color: #ffffff;
            cursor: pointer;
            font-weight: 700;
            padding: 7px 15px;
            font-size: 14px;
            box-sizing: border-box;
            vertical-align: middle;
            border-radius: 3px;
        }
    }

    &-Asociaciones {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        margin-block-end: 30px;
        padding: 0 20px;
        padding-block-end: 30px;
        border-bottom: 1px dashed;
        text-align: center;

        @include mobile {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }

        .asociaciones-title {
            line-height: 29px;
            font-weight: 300;
            color: #344079;
            letter-spacing: 0;
            text-transform: uppercase;
            margin: 20px auto;
            font-size: 18px;
        }

        .asociaciones-descripcion {
            font-size: 14px;
            color: #223041;
            margin-block-end: 10px;
        }

        .asociacionItem {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .img {
                width: 17%;
                
                img {
                    max-width: 100px;
                }
            }

            .name {
                width: 50%;
                font-family: 'Lato', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #344079;
                text-transform: uppercase;
                text-align: start;

                @include mobile {
                    font-size: 14px;
                    text-align: center;               
                }
            }

            .button {
                width: 33%;
                text-align: end;

                span {
                    color: #1979c3;
                    cursor: pointer;
                }
            }
        }

        .asociacionesDonacion {
            .button {
                text-align: start;

                span {
                    color: #1979c3;
                    cursor: pointer;

                    svg {
                        width: 22px;
                        height: 13px;
                    }
                }
            }

            .content {
                .title {
                    line-height: 29px;
                    font-weight: 300;
                    color: #344079;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    margin: 20px auto;
                    font-size: 18px;
                }

                .img img {
                    max-width: 300px;
                }

                .descripcion {
                    margin: 0 auto;
                    margin-block-start: 20px;
                    font-size: 16px;
                    padding: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    text-align: justify;

                    @include desktop {
                        width: 80%;
                    }
                }

                .form-asociacion {
                    width: 50%;
                    max-width: 600px;
                    margin: 0 auto;

                    @include mobile {
                        width: 90%;
                    }

                    .BtnAsociacion {
                        background: #081d4b;
                        border: 1px solid #081d4b;
                        color: #ffffff;
                        cursor: pointer;
                        font-weight: 700;
                        padding: 7px 15px;
                        font-size: 14px;
                        box-sizing: border-box;
                        vertical-align: middle;
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    &-RewardTransactions {
        @include desktop {
            padding: 0 20px;
        }

        .rewards-title {
            padding-block-end: 10px;
            border-bottom: 1px solid;
        }

        .transactions-table {
            width: 100%;
        }
        
        tbody {
            display: flex;
            flex-direction: column;
        }
        
        tr {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 5px 0;
        }

        .transaction-id {
            width: 50%;
            padding-block-end: 0;
        }

        .transaction-date {
            width: 50%;
            text-align: end;
            padding-block-end: 0;
        }

        .transaction-comment {
            width: 60%;
        }

        .transaction-amount {
            width: 40%;
            text-align: end;
        }
    }

    &-TablePagination {
        text-align: center;
        color: #333;
        font-size: 14px;

        #page-numbers {
            margin-block-start: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .disableActionPagination {
                color: #666 !important;
            }

            .disableActionPagination, .enableActionPagination, .number-page, .number-page-selected {
                min-width: 1.5em;
                padding: 0.5em 1em;
                margin-inline-start: 2px;
                text-align: center;
                text-decoration: none !important;
                cursor: pointer;
                border-radius: 2px;
            }

            .number-page-selected {
                color: #fff !important;
                border: 1px solid #00295a;
                background-color: #00295a;
            }
        }
    }

    .rewards-title {
        font-family: 'Lato', sans-serif;
        line-height: 29px;
        font-weight: 300;
        color: #344079;
        letter-spacing: 0;
        text-transform: uppercase;
        margin: 20px auto;
        font-size: 18px;
        text-align: center;

        @include mobile {
            font-family: "futura-pt-book", sans-serif;
            font-size: 16px;
            margin: 0 0 20px;
            padding: 10px 0 0;
            color: #363b4f;
            font-weight: 600;
            letter-spacing: 1px;
            border: none;
            text-align: start;
            text-transform: none;
        }
    }

    &-CondicionesGansociety {
        font-family: "futura-pt-light", sans-serif;
        font-size: 14px;
        color: #363b4f;

        @include desktop {
            margin: 30px 0;
            padding-block-end: 20px;
            border-bottom: 1px dashed;
        }

        .pagebuilder-column-line {
            @include mobile {
                display: flex;
                flex-direction: column;
            }

            .pagebuilder-column {
                margin-block-end: 17px;

                @include mobile {
                    width: 100% !important;
                    padding: 0 !important;
                }
            }
        }

        strong {
            font-size: 16px;
            font-family: "futura-pt-demi", sans-serif;
            font-weight: normal;
        }

        p {
            margin-block-end: 0;
        }
    }
}
