/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
 

.CookiePopup {
    z-index: 1000;
    width: 100%;
    inset-block-end: 0;
    padding: 5px 20px;
    opacity: 1;
    background-color: white;
    font-size: 14px;
    color: #011547;
    font-family: 'Lato', sans-serif;
    margin: 0;

    @include mobile {
        padding: 10px 20px;
    }

    &-Content {
        color: #011547;
        font-weight: 400;
        text-align: start;
        margin-block-end: 10px;
    }

    &-Link {
        margin-inline-start: 5px;
    }

    &-Gestionar {
        text-transform: uppercase;
        min-width: max-content;
        text-align: center;

        &:hover {
            color: #216ba5;
            cursor: pointer;
        }

        span {
            display: inline-block;
            border-bottom: 1px solid #011547;
            padding-block-end: 2px;
        }
    }

    &-CTA {
        color: white;
        background-color: #011547;
        height: 35px !important;
        font-weight: 400;

        @include desktop {
            margin-inline-start: 15px;
        }

        &:hover {
            background-color: #216ba5;
        }
    }

    &-GestionarPopup {
        .Popup-Heading {
            font: normal normal 400 20px/24px 'Lato',sans-serif !important;
            color: #011547;
            letter-spacing: 1.5px !important;
            border-bottom: 2px solid #011547;
            padding-block-end: 10px;
        }

        @include mobile {
            inset-block-start: 45px;
            height: calc(100vh - 45px);

            .Popup-Content {
                padding: 0;
                overflow-y: hidden;
            }
        }
    }

    &-ContentPopup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 800px;
        font-family: 'Lato', sans-serif;

        @include mobile {
            flex-direction: column;
        }
    }

    &-TableTabs {
        width: 20%;
        padding-inline-end: 10px;
        border-inline-end: 1px solid #011547;

        @include mobile {
            width: 100%;
            display: flex;
            border-block-end: 1px solid #011547;
            border-inline-end: none;
            justify-content: space-around;
        }
    }

    &-popupTab {
        font-size: 18px;
        color: #011547;
        line-height: 60px;

        span {
            cursor: pointer;

            @include mobile {
                font-size: 15px;
            }
        }

        &_isActive {
            span {
                font-weight: bold;
                border-bottom: 2px solid #011547;
            }
        }
    }

    &-TableContent {
        width: 80%;
        padding: 0 20px;

        @include mobile {
            width: 100%;
            padding: 0 10px;
        }
    }

    &-TableContentTitle {
        height: 50px;
        text-align: center;

        span {
            line-height: 50px;
            font-size: 20px;
            font-weight: bold;
            color: #011547;
        }

        .switch {
            display: inline-block;
            height: 30px;
            width: 60px;
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 50%;
            transform: translateY(-50%);
        }
        
        .switch input {
            display: none;
        }
        
        .slider {
            background-color: #ccc;
            inset-block-end: 0;
            cursor: pointer;
            inset-inline-start: 0;
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 0;
            transition: .4s;
        }
        
        .slider::before {
            background-color: #fff;
            inset-block-end: 4px;
            content: "";
            width: 22px;
            height: 22px;
            inset-inline-start: 4px;
            position: absolute;
            transition: .4s;
        }
        
        input:checked + .slider {
            background-color: #011547;
        }
        
        input:checked + .slider::before {
            transform: translateX(30px);
        }
        
        .slider.round {
            border-radius: 30px;
        }
        
        .slider.round::before {
            border-radius: 50%;
        }
    }

    &-TableContentDescription {
        max-height: 400px;
        min-height: 400px;
        overflow-y: auto;

        @include mobile {
            max-height: calc(100vh - calc(45px + 61px + 50px + 60px));
            min-height: calc(100vh - calc(45px + 61px + 50px + 60px));
        }

        span {
            font-size: 16px;
            font-weight: 400;
            color: #011547;
            letter-spacing: 1.5px;
        }
    }

    &-Table {
        margin-block-start: 10px;
        min-width: 100%;

        @include mobile {
            width: calc(100vw - 20px);
        }

        td, th {
            min-width: auto;
            background-color: transparent;
            color: #011547;

            @include mobile {
                padding: 5px;
            }

            &:first-child {
                padding-inline-start: 0;
            }
        }

        .Loader {
            margin-block-start: 60px;
        }
    }

    &-PopupButtons {
        width: 100%;
        margin-block-start: 20px;
        text-align: end;
    
        @include mobile {
            text-align: center !important;
            display: flex;
            padding: 0 10px 10px;
        }

        .Button {
            height: 30px !important;
            line-height: 17px;
            padding: 4px 30px !important;
            font-weight: 400;
            display: inline-block;

            @include mobile {
                font-size: 12px;
            }
        }

        .Button:first-child {
            margin-inline-end: 20px;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    @include mobile {
        .CookiePopup-TableContentDescription {
            max-height: calc(100vh - calc(120px + 61px + 50px + 60px));
            min-height: calc(100vh - calc(120px + 61px + 50px + 60px));
        }
    }
}
