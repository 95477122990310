/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
 

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PayPalBillingAgreementsPage {
    min-height: 80%;
    font-size: 14px;
    width: 100%;

    @include narrow-desktop {
        max-width: 638px;
    }

    @include tablet {
        max-width: 527px;
    }

    @include mobile {
        min-height: 80vh;
    }

    &-BlockTitle {
        border-bottom: var(--my-account-content-border);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        font-size: 18px;
        padding-block-end: 6px;
        width: 100%;
        margin-block-end: 25px;

        a {
            margin-inline-start: 15px;
            margin-block-start: 3px;
        }

        @include desktop {
            justify-content: unset;
        }

        @include mobile {
            flex-flow: column wrap;
            width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2))
        }
    }

    &-TableWrapper {
        width: 100%;
        overflow-x: auto;

        @include mobile {
            width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2))
        }
    }

    &-CreateNewAgreement {
        .Field {
            margin-block-end: 24px;
        }
    }
}
